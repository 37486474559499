import { gql } from "@apollo/client";

export const INITIATE_WEBAUTHN_REGISTRATION = gql`
  mutation InitiateWebAuthnRegistration {
    initiateWebAuthnRegistration {
      challenge
      rp {
        name
      }
      user {
        id
        name
        displayName
      }
      pubKeyCredParams {
        alg
        type
      }
      timeout
      attestation
    }
  }
`;

export const COMPLETE_WEBAUTHN_REGISTRATION = gql`
  mutation CompleteWebAuthnRegistration($clientData: String!) {
    completeWebAuthnRegistration(clientData: $clientData)
  }
`;

export const INITIATE_WEBAUTHN_LOGIN = gql`
  mutation InitiateWebAuthnLogin($email: String!) {
    initiateWebAuthnLogin(email: $email) {
      userId
      challenge
      allowCredentials {
        id
        type
      }
      timeout
      userVerification
    }
  }
`;

export const VERIFY_WEBAUTHN_LOGIN = gql`
  mutation VerifyWebAuthnLogin($userId: String!, $assertion: String!) {
    verifyWebAuthnLogin(userId: $userId, assertion: $assertion)
  }
`;

export const UPDATE_TWOFA_METHOD = gql`
  mutation updateTwofaMethod($methodId: String!, $isEnabled: Boolean!) {
    updateTwofaMethod(methodId: $methodId, isEnabled: $isEnabled) {
      successfullyUpdated
    }
  }
`;

export const DELETE_TWOFA_METHOD = gql`
  mutation deleteTwofaMethod($methodId: String!) {
    deleteTwofaMethod(methodId: $methodId) {
      successfullyDeleted
    }
  }
`;
