import { useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as SvgLogo } from "../../../assets/img/common/logo.svg";
import CallIcon from "../../../assets/svgs/call-out.svg";
import MailIcon from "../../../assets/svgs/mail-fast.svg";
import { GlobalErrorHandler } from "../../../components/error/global_error.component";
import UnderMaintenance from "../../../components/modal/under-maintenance";
import {
  MUTATION_SEND_OTP,
  MUTATION_VERIFY_OTP,
} from "../../../graphql/mutations/user";
import { AppRoute } from "../../../routes";
import { AuthService } from "../../../services/auth.service";

export function OtpPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [infoMessage, setInfoMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [verifyOtp, { loading: verificationLoading, error }] = useMutation(
    MUTATION_VERIFY_OTP,
    { errorPolicy: "all" }
  );
  const [sendOtp, { loading: sendOtpLoading, error: sendOtpError }] =
    useMutation(MUTATION_SEND_OTP, { errorPolicy: "all" });
  const [deviceId, setDeviceId] = useState<string>("");
  const [otpSent, setOtpSent] = useState<boolean>(false);
  const [otpMethod, setOtpMethod] = useState<"phone" | "email" | null>("email");
  const [userId, setUserId] = useState<string>("");
  const [countdown, setCountdown] = useState<number>(0);
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  async function resendLink() {
    setInfoMessage("Resending OTP. Please wait...");
    setIsResendDisabled(true);
    setErrorMessage("");
    await sendOtp({ variables: { userId, deviceId, method: otpMethod } });
    setInfoMessage("OTP sent successfully");
    setErrorMessage("");
    // Reset countdown and disable the resend button
    setCountdown(30);
  }

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("token")?.trim();
    const deviceId = localStorage.getItem("deviceId")?.trim();
    if (isAuthenticated && deviceId) {
      navigate(AppRoute.Home);
    }
  }, []);

  useEffect(() => {
    const deviceIdToSet = id || localStorage.getItem("deviceId") || "";
    const userIdToSet = localStorage.getItem("userId") || "";

    if (!deviceIdToSet || !userIdToSet) {
      navigate(AppRoute.AccountLogin);
    } else {
      setDeviceId(deviceIdToSet);
      setUserId(userIdToSet);
    }
  }, [location.search, navigate]);

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    setIsResendDisabled(true);
    setInfoMessage("Verifying OTP. Please wait...");
    setErrorMessage("");

    const { data } = await verifyOtp({
      variables: { userId, deviceId, otp: verificationCode, method: otpMethod },
    });
    if (data) {
      AuthService.login(
        data.verifyOTP.user.email,
        data.verifyOTP.token,
        deviceId,
        null
      );
      setInfoMessage("OTP verified successfully");
      setErrorMessage("");
      setOtpSent(false);
      navigate(AppRoute.Home);
    } else {
      setErrorMessage("OTP verification failed");
      setIsResendDisabled(false);
      setInfoMessage("");
    }
  }

  useEffect(() => {
    if (countdown === 0) {
      setIsResendDisabled(false);
      return;
    }
    const timer = setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer
  }, [countdown]);

  async function handleOtpMethod(method: "phone" | "email" | null) {
    setOtpMethod(method);
    setInfoMessage(`Sending OTP to your ${method}. Please wait...`);
    setErrorMessage("");

    try {
      const response = await sendOtp({
        variables: { userId, deviceId, method },
      });
      if (response.errors) {
        setErrorMessage(response.errors[0].message);
      } else {
        setInfoMessage(
          `OTP sent to your ${method}. Please check your inbox or messages.`
        );
        setOtpSent(true);
        setCountdown(30);
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }

  const handleCloseUnderMaintenance = () => {
    setErrorMessage("");
  };

  return (
    <div className="bg-gradient-to-br to-[#F7C791] from-[#FDF5EC] via-[#F8D5AE] w-full py-[2rem] content-center">
      <section className="flex flex-col content account bg-white shadow-xl rounded-xl py-12 mx-auto my-auto w-[90%] md:w-[70%] lg:w-[60%] mt-[3rem] self-center">
        <GlobalErrorHandler />
        <UnderMaintenance
          errorType={errorMessage}
          onClose={handleCloseUnderMaintenance}
        />
        <SvgLogo className="mx-auto text-center max-w-52" />
        <h1
          data-test="heading"
          className="mt-16 mb-4 font-sans text-2xl font-bold leading-normal tracking-wide text-center md:text-5xl text-deep-blue "
        >
          Welcome to Propnerd
        </h1>
        <p className="mt-8 mb-12 text-center md::max-w-[70%]">
          Thank you for embarking on your investment journey with us. At
          PropNerd, we're dedicated to fostering a secure, transparent, and
          seamless environment for our valued investors. To ensure the highest
          standards of safety and compliance, we adhere to rigorous Know Your
          Customer (KYC) and Anti-Money Laundering (AML) protocols.
        </p>
        {!otpSent ? (
          <div className="flex flex-col items-center gap-4 max-w-full md:max-w-[80%] xl:max-w-[70%] p-3 md:px-8 py-12 mx-auto bg-white shadow-xl rounded-xl">
            <div className="flex justify-center gap-4">
              <div
                className={`cursor-pointer max-w-[14rem] rounded-[1rem] p-3 md:p-4 transition-colors duration-300 ${otpMethod === "email" ? "bg-deep-blue text-white" : "bg-gray-100 text-deep-blue border-[1px]"}`}
                onClick={() => setOtpMethod("email")}
                data-test="verify-email"
              >
                <div className="flex items-center justify-between mb-2">
                  <img
                    src={MailIcon}
                    alt="email"
                    className="bg-white rounded-full border border-gray-200 p-1.5 w-10 h-10"
                  />
                  <span
                    className={`flex self-start w-6 h-6 rounded-full border-[0.22rem] ${otpMethod === "email" ? "border-yellow-400" : "border-gray-400"} text-blue-900 font-bold`}
                  ></span>
                </div>
                <div className="gap-1">
                  <p
                    className={`md:text-lg font-bold ${otpMethod === "email" ? "text-white" : "text-deep-blue"} mt-2`}
                  >
                    Via Email
                  </p>
                  <p className="text-gray-500 font-semibold md:text-lg leading-[1.2rem] font-bold">
                    Get your OTP sent to your registered email.
                  </p>
                </div>
              </div>
              <div
                className={`cursor-pointer max-w-[14rem] rounded-[1rem] p-3 md:p-4 transition-colors duration-300 ${otpMethod === "phone" ? "bg-deep-blue text-white" : "bg-gray-100 text-deep-blue border-[1px]"}`}
                onClick={() => setOtpMethod("phone")}
                data-test="verify-phone"
              >
                <div className="flex items-center justify-between mb-2">
                  <img
                    src={CallIcon}
                    alt="phone"
                    className="bg-white rounded-full border border-gray-200 p-1.5 w-10 h-10"
                  />
                  <span
                    className={`flex self-start w-6 h-6 rounded-full border-[0.22rem] ${otpMethod === "phone" ? "border-yellow-400" : "border-gray-400"} text-blue-900 font-bold`}
                  ></span>
                </div>
                <div className="p-0">
                  <p
                    className={`md:text-lg font-bold ${otpMethod === "phone" ? "text-white" : "text-deep-blue"} mt-2`}
                  >
                    Via Phone
                  </p>
                  <p className="text-gray-500 font-semibold md:text-lg leading-[1.2rem] font-bold">
                    Get your OTP sent to your phone via SMS.
                  </p>
                </div>
              </div>
            </div>
            {errorMessage && errorMessage !== "UNDER_MAINTENANCE" && (
              <div
                className="p-5 mt-5 mb-5 font-medium text-red-500 border border-gray-300 rounded-md bg-gray-50"
                data-test="error-message"
              >
                {errorMessage}
              </div>
            )}
            <button
              className="w-full h-12 mt-5 mb-2 font-bold text-white rounded cursor-pointer btn btn-primary disabled:opacity-50"
              type="button"
              data-test="sendcode-btn"
              onClick={() => handleOtpMethod(otpMethod)}
              disabled={sendOtpLoading}
            >
              {sendOtpLoading ? "Sending..." : "Send Code"}
            </button>
          </div>
        ) : (
          <form
            className="w-1/2 max-w-xl p-8 mx-auto bg-white rounded-lg shadow-md card"
            onSubmit={onSubmit}
          >
            <p className="mb-8 text-center">
              We've sent a One-Time Password (OTP) to your {otpMethod}. Please
              enter it below.
            </p>
            <div className="flex flex-col gap-2 mb-4">
              <label className="mb-2 -mt-2 text-base font-bold tracking-normal">
                Verification code
              </label>
              <input
                data-test="verification-code"
                className="box-border w-full p-4 text-base border border-gray-300 rounded"
                autoFocus
                type="text"
                value={verificationCode}
                placeholder="Enter your verification code"
                onChange={(e) => setVerificationCode(e.target.value)}
              />
            </div>

            {infoMessage && (
              <div
                data-test="info-message"
                className="px-12 py-6 mb-3 border rounded-lg bg-light-grey"
              >
                {infoMessage}
              </div>
            )}
            {errorMessage && errorMessage !== "UNDER_MAINTENANCE" && (
              <div
                data-test="error-message"
                className="px-12 py-6 mb-3 mb-12 text-red-500 border rounded-lg bg-light-grey -600"
              >
                {errorMessage}
              </div>
            )}
            {countdown > 0 && (
              <div className="flex justify-center px-4">
                <h5 className="px-2 text-bold">Resend available in</h5>
                <h5 data-test="countdown" className="text-bold text-yellow">
                  00:{countdown} Sec
                </h5>
              </div>
            )}
            <div className="flex flex-wrap justify-center gap-2 p-4 mt-6">
              <button
                data-test="verify-btn"
                type="submit"
                className="btn btn-primary"
                style={{ height: "3rem" }}
                disabled={verificationLoading}
              >
                Verify
              </button>
              <button
                data-test="resend-btn"
                className="btn btn-secondary disabled:opacity-20"
                type="button"
                onClick={resendLink}
                style={{ height: "3rem" }}
                disabled={isResendDisabled || sendOtpLoading}
              >
                Resend link
              </button>
            </div>
          </form>
        )}
      </section>
    </div>
  );
}
