import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import VerificationImg from "../../../assets/svgs/7030148_security_locked_ui basic_lock_protection_icon.svg";
import { GlobalErrorHandler } from "../../../components/error/global_error.component";
import { QUERY_GET_ME } from "../../../graphql/queries/get-me";
import { QUERY_GET_SUMSUB_WEB_SDK_LINK } from "../../../graphql/queries/get-sumsub-link";
import UnderMaintenance from "../../../components/modal/under-maintenance";
import { User, UserStatus } from "../../../__generated__/graphql";
import { ReactComponent as SvgLogo } from "../../../assets/img/common/logo.svg";
import IdCardIcon from "../../../assets/svgs/id-card.svg";
import CameraIcon from "../../../assets/svgs/camera.svg";
import { QUERY_GET_SYS_CONFIG } from "../../../graphql/queries/get-config";
import { processSysConfigs } from "../../../utils/config.util";
import LoadingSite from "./loader/loading_site";

export function KycCheck() {
  const [iframeUrl, setIframeUrl] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const storedKycOnRegister = localStorage.getItem("kycOnRegister") === "true";
  const storedUserStatus = localStorage.getItem("userStatus");

  const {
    refetch,
    data: user,
    loading: userLoading,
  } = useQuery<{ me: User }>(QUERY_GET_ME, {
    fetchPolicy: "network-only",
  });
  const [getSumsubWebSdkLink] = useLazyQuery(QUERY_GET_SUMSUB_WEB_SDK_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.sumsubWebSdkLink) {
        setIframeUrl(data.sumsubWebSdkLink);
      }
    },
  });
  const [kycOnRegister, setKycOnRegister] = useState<boolean | null>(null);
  const [userStatus, setUserStatus] = useState<string | null>(null);
  const { data: configData, loading: configLoading } = useQuery(
    QUERY_GET_SYS_CONFIG,
    {
      fetchPolicy: "network-only",
    }
  );

  function handleSumsubVerification() {
    getSumsubWebSdkLink();
  }

  const handleCloseUnderMaintenance = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    if (configData) {
      const processedConfigs = processSysConfigs(configData.getAllConfigs);
      setKycOnRegister(processedConfigs.kycOnRegister);
      localStorage.setItem("kycOnRegister", processedConfigs.kycOnRegister);
    }
    if (user) {
      setUserStatus(user?.me.status);
      localStorage.setItem("userStatus", user?.me.status);
    }
  }, [configData, user]);

  useEffect(() => {
    if (userStatus !== "active" && kycOnRegister) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto"; // Ensure cleanup when component unmounts
    };
  }, [userStatus, kycOnRegister]);

  // iframe is getting predefined styles from sumsub, which is making it disappear from our website.
  //they were forcefully applied by them which override our css styles too
  //this useEffect is responsible to handle that issue
  useEffect(() => {
    const iframe = document.querySelector(
      ".kyc-iframe-container iframe"
    ) as HTMLIFrameElement | null;

    if (iframe) {
      iframe.style.position = "relative";
      iframe.style.left = "0";

      // Setting up a MutationObserver to handle changes to the style attribute
      const observer = new MutationObserver(() => {
        iframe.style.position = "relative";
        iframe.style.left = "0";
      });

      observer.observe(iframe, {
        attributes: true,
        attributeFilter: ["style"],
      });

      return () => observer.disconnect();
    }
  }, [iframeUrl]);

  // Start querying user every 2 seconds if iframeUrl is present and user status is not Active
  useEffect(() => {
    if (iframeUrl && userStatus !== "active") {
      const id = setInterval(() => {
        refetch(); // Re-fetch user data every 2 seconds
      }, 2000);

      setIntervalId(id);

      return () => {
        clearInterval(id); // Clean up interval when component unmounts or conditions change
      };
    }

    // If iframeUrl is not present or status is Active, clear the interval
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [iframeUrl, user?.me.status, refetch]);

  const fullScreenStyle =
    "fixed inset-0 flex flex-col items-center bg-gradient-to-br to-[#F7C791] from-[#FDF5EC] via-[#F8D5AE] z-[999999] overflow-auto px-6 py-[3rem]";

  if (
    storedUserStatus !== "Active" &&
    storedKycOnRegister &&
    (!user || !configData || configLoading || userLoading)
  ) {
    return <LoadingSite />;
  }
  if (storedUserStatus !== "active" && storedKycOnRegister) {
    switch (user?.me.status) {
      case UserStatus.Unverified:
        return (
          <div className={`${fullScreenStyle} justify-start`}>
            <GlobalErrorHandler />
            <div className="bg-white p-8 shadow-xl rounded-2xl text-center max-w-[600px] w-full">
              <SvgLogo className="w-[12rem] lg:w-40 mx-auto mb-6" />
              <h2 className="mb-4 text-3xl font-bold text-deep-blue">
                Verify Your Identity
              </h2>
              <p className="mb-6 text-gray-600 text-md">
                To unlock all our exclusive features, please complete the KYC
                verification process.
              </p>
              {iframeUrl ? (
                <div className="p-6 border-t-2 border-[#F8D5AE] shadow-lg rounded-xl kyc-iframe-container">
                  <iframe
                    src={iframeUrl}
                    title="Sumsub Verification"
                    width="100%"
                    height="700px"
                    allow="camera; microphone"
                  ></iframe>
                </div>
              ) : (
                <>
                  <div className="flex flex-col items-start p-5 mb-10">
                    <div className="flex items-center space-x-4">
                      <img
                        src={IdCardIcon}
                        alt="ID Card"
                        className="object-contain w-5 h-5 mb-4"
                      />
                      <div className="flex-shrink text-start">
                        <p className="text-lg font-bold text-primary">
                          ID Card Verification
                        </p>
                        <p className="pt-1 text-base font-semibold text-gray-600">
                          We will ask you to upload your ID document
                        </p>
                      </div>
                    </div>
                    <div className="w-full mx-auto my-4 border-b border-gray-200" />
                    <div className="flex items-center space-x-4">
                      <img
                        src={CameraIcon}
                        alt="Selfie"
                        className="object-contain w-5 h-5 mb-4"
                      />
                      <div className="flex-shrink text-start">
                        <p className="text-lg font-bold text-primary">
                          Selfie Verification
                        </p>
                        <p className="pt-1 text-base font-semibold text-gray-600">
                          We will ask you to take a selfie to confirm it's you
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="p-6 bg-[#FDF5EC] border border-[#F8D5AE] shadow-lg rounded-xl">
                    <img
                      src={VerificationImg}
                      alt="Verification"
                      className="w-24 h-24 mx-auto mb-4"
                    />
                    <h3 className="mb-4 text-xl font-bold text-deep-blue">
                      Verify to Explore
                    </h3>
                    <p className="mb-6 text-gray-500 text-md">
                      Complete your KYC verification to unlock the ability to
                      deposite funds and start investing. This ensures the
                      security and integrity of our platform.
                    </p>
                    <button
                      className="bg-deep-blue hover:bg-[#FFD000] text-white hover:text-deep-blue font-semibold px-6 py-3 rounded-lg"
                      onClick={handleSumsubVerification}
                    >
                      Start Verification
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        );

      case UserStatus.VerificationInProgress:
        return (
          <div className={`${fullScreenStyle} justify-center`}>
            <GlobalErrorHandler />
            <UnderMaintenance
              errorType={errorMessage}
              onClose={handleCloseUnderMaintenance}
            />
            <SvgLogo className="w-[8rem] md:w-[10rem] lg:w-[12rem] mx-auto mb-6" />

            <div className="bg-white p-8 shadow-xl rounded-2xl text-center max-w-[600px] w-full">
              <img
                src={VerificationImg}
                alt="Verification"
                className="w-24 h-24 mx-auto mb-4"
              />
              <h2 className="mb-4 text-2xl font-bold text-deep-blue">
                Verification In Progress
              </h2>
              <div className="p-6 bg-[#FDF5EC] border border-[#F8D5AE] shadow-lg rounded-xl">
                <p className="text-lg text-gray-600">
                  Your application is being reviewed to comply with our KYC
                  requirements. You will be able to deposit money and start
                  investing with PropNerd once your verification is complete.
                </p>
                <p className="text-lg text-gray-600">
                  An email will be sent to you upon completion of the
                  verification process.
                </p>
              </div>
            </div>
          </div>
        );

      case UserStatus.Blocked:
        return (
          <div
            className={`${fullScreenStyle} bg-gradient-to-r from-red-200 via-red-300 to-red-400 justify-center`}
          >
            <GlobalErrorHandler />
            <SvgLogo className="w-[8rem] md:w-[10rem] lg:w-[12rem] mx-auto mb-6" />

            <div className="bg-white p-8 shadow-xl rounded-2xl text-center max-w-[600px] w-full border-t-4 border-red-500">
              <img
                src={VerificationImg}
                alt="Blocked"
                className="w-24 h-24 mx-auto mb-4"
              />
              <h2 className="mb-4 text-2xl font-bold text-red-600">
                Account Blocked
              </h2>
              <div className="p-6 bg-[#FDECEC] border border-[#F8AEAE] shadow-lg rounded-xl">
                <p className="text-lg text-gray-600">
                  Unfortunately, your KYC verification did not meet our
                  requirements. You currently will not be able to deposit money
                  or invest with PropNerd.
                  <br /> Please contact support at{" "}
                  <a
                    href="mailto:info@propnerd.io"
                    className="font-bold text-deep-blue hover:underline"
                  >
                    info@propnerd.io
                  </a>{" "}
                  for assistance.
                </p>
              </div>
            </div>
          </div>
        );
      case UserStatus.Active:
        document.body.style.overflow = "auto";
        return null;

      default:
        return null;
    }
  }

  return null;
}
