import { useLazyQuery, useMutation } from "@apollo/client";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useEffect, useRef, useState } from "react";
import PhoneInputWithCountrySelect, {
  isValidPhoneNumber,
} from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as SvgLogo } from "../../../assets/img/common/logo.svg";
import CountryList from "../../../assets/json/countries.json";
import { ReactComponent as EyeSlashIcon } from "../../../assets/svgs/eye-slash.svg";
import { ReactComponent as EyeIcon } from "../../../assets/svgs/eye.svg";
import UnderMaintenance from "../../../components/modal/under-maintenance";
import ProgressSteps from "../../../components/register/progress-steps.component";
import RangeSlider from "../../../components/register/range-slider";
import { MUTATION_REGISTER } from "../../../graphql/mutations/register";
import { QUERY_IS_PERSONAL_INFO_VALID } from "../../../graphql/queries/validate-personal-info";
import { AppRoute } from "../../../routes";
import { AuthService } from "../../../services/auth.service";
import { TermsText } from "./terms";

interface Step {
  step: number;
  label: string;
  title?: string;
  description?: string;
}

const PrioritisedCountries = ["GB", "US", "CA", "FR", "DE", "IT", "ES"];

const steps: Step[] = [
  {
    step: 1,
    label: "Personal\nDetails",
    title: "Tell Us About Yourself",
    description: "Enter your details to set up your account.",
  },
  {
    step: 2,
    label: "Investor\nProfile",
    title: "Investor Details",
    description: "Provide your investment details to proceed.",
  },
  {
    step: 3,
    label: "Term &\nServices",
    title: "Terms & Services",
    description: "Review and accept the terms to continue.",
  },
  {
    step: 4,
    label: "Verify\nOTP",
    title: "Verification",
    description: "Please verify either your phone or email to continue.",
  },
  {
    step: 5,
    label: "KYC",
    title: "Let's Verify Your Identity",
    description:
      "To deposit or make an investment, we require you to complete our KYC verification.",
  },
];

const Countries = PrioritisedCountries.map((code) => ({
  code,
  name: CountryList.find((country) => country.code === code)?.name,
})).concat(
  CountryList.filter(
    (country) => !PrioritisedCountries.includes(country.code)
  ).map((country) => ({
    code: country.code,
    name: country.name,
  }))
);

export function AccountRegisterPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("UK");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [infoMessage, setInfoMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isChecked, setIsChecked] = useState({
    over18: false,
    termsAgreed: false,
    qualifiedInvestor: false,
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [aggression, setAggression] = useState("Conservative");
  const [duration, setDuration] = useState(2);
  const [deviceId, setDeviceId] = useState<string>("");
  const [currentStep, setCurrentStep] = useState(1);
  const formSectionRef = useRef<HTMLDivElement | null>(null);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const navigate = useNavigate();
  const websiteUrl = process.env.REACT_APP_WEBSITE_URL;

  const [register, { data: registerData, error: registerError }] = useMutation(
    MUTATION_REGISTER,
    { errorPolicy: "all" }
  );
  const [
    validatePersonalInfo,
    {
      data: validationResponse,
      loading: validationLoading,
      error: validationError,
    },
  ] = useLazyQuery(QUERY_IS_PERSONAL_INFO_VALID, { errorPolicy: "all" });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function validateEmail(emailTest: string) {
    return emailRegex.test(emailTest);
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setIsChecked((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  useEffect(() => {
    FingerprintJS.load().then((fp) => {
      fp.get().then((result) => {
        setDeviceId(result.visitorId);
      });
    });
  }, []);

  useEffect(() => {
    if (currentStep === 1 && isFirstLoad) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else if (!isFirstLoad && formSectionRef.current) {
      formSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start", // Align the element to the top of the viewport
      });
    }

    if (currentStep > 1) {
      setIsFirstLoad(false);
    }
  }, [currentStep, isFirstLoad]);

  async function validatePersonalData() {
    setIsValidating(true);
    setErrorMessage(""); // Clear previous error messages
    if (!firstName || !lastName || !email || !phone || !password || !country) {
      setLoading(false);
      setIsValidating(false);
      setErrorMessage("Please fill in all the fields.");
      return;
    }

    if (!validateEmail(email)) {
      setLoading(false);
      setIsValidating(false);
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    if (password.length < 8) {
      setLoading(false);
      setIsValidating(false);
      setErrorMessage("Password must be at least 8 characters long.");
      return;
    }

    // Validate phone number
    if (!isValidPhoneNumber(phone)) {
      setLoading(false);
      setIsValidating(false);
      setErrorMessage("Please enter a valid phone number.");
      return;
    }

    const response = await validatePersonalInfo({
      variables: {
        email,
        phone,
        password,
        firstName,
        lastName,
        country,
      },
    });

    if (response?.data?.validatePersonalInfo?.success) {
      setCurrentStep((prevStep) => prevStep + 1);
      setIsValidating(false);
      setErrorMessage(""); // Clear error message
    }
  }

  const userAgent = navigator.userAgent;
  const osVersion = getOSVersion(userAgent);
  const deviceType = getDeviceType(userAgent);
  const model = getModel(userAgent);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setInfoMessage(undefined);
    setErrorMessage(undefined);
    setLoading(true);
    setSubmitted(false);

    const { over18, termsAgreed, qualifiedInvestor } = isChecked;
    if (!over18 || !termsAgreed || !qualifiedInvestor) {
      setLoading(false);
      setErrorMessage("Please accept all agreements to create your account.");
      return;
    }

    setInfoMessage("Processing your request. Please wait...");

    // Submit form
    const data = {
      firstName,
      lastName,
      phone: phone,
      password,
      email,
      country,
      investmentDuration: duration,
      investmentApproach: aggression,
    };

    const deviceData = {
      deviceId,
      osVersion,
      deviceType,
      model,
    };

    const { data: userData } = await register({
      variables: { ...data, ...deviceData },
    });
    console.log(userData);
    localStorage.setItem("kycOnRegister", userData?.register.kycOnRegister);
    if (userData.register.deviceStatus === "active") {
      AuthService.login(
        email,
        userData.register.accessToken,
        deviceId,
        userData.register.lastLogin
      );
      localStorage.setItem("userId", userData.register.id);
      navigate(AppRoute.Home);
    } else {
      localStorage.setItem("deviceId", deviceId);
      localStorage.setItem("userId", userData.register.id);
      navigate(`${AppRoute.LoginVefitication}?id=${deviceId}`);
    }
  }

  useEffect(() => {
    if (!AuthService.getToken()) {
      localStorage.clear();
      sessionStorage.clear();
    } else {
      navigate(AppRoute.Home);
    }
  }, []);

  useEffect(() => {
    if (registerError) {
      console.error("Registration error:", registerError);
      setLoading(false);
      setInfoMessage(undefined);
      setErrorMessage(registerError.message);
    } else if (registerData) {
      setLoading(false);
      setSubmitted(true);
      setInfoMessage(
        "Your request has been submitted successfully. Please check your email for the next steps."
      );
      localStorage.setItem("deviceId", deviceId);
      localStorage.setItem("userId", registerData.register?.id);
      setInfoMessage("Account created successfully!");

      if (currentStep < steps.length) {
        const nextStep = currentStep + 1;
        setCurrentStep(nextStep);
      }
      setErrorMessage("");
    }
  }, [registerData, registerError]);

  useEffect(() => {
    if (validationError) {
      console.error("Validation error:", validationError);
      setInfoMessage(undefined);
      setErrorMessage(validationError.message);
      setIsValidating(false);
    }
  }, [validationResponse, validationError]);

  useEffect(() => {
    setErrorMessage("");
  }, [currentStep]);

  const onAggressionChange = (value: string | number) => {
    setAggression(value as string);
  };

  const onDurationChange = (value: string | number) => {
    setDuration(value as number);
  };

  function getOSVersion(userAgent: string) {
    let osVersion = "Unknown OS Version";

    if (/Windows NT 10.0/.test(userAgent)) osVersion = "Windows 10";
    else if (/Windows NT 6.3/.test(userAgent)) osVersion = "Windows 8.1";
    else if (/Windows NT 6.2/.test(userAgent)) osVersion = "Windows 8";
    else if (/Windows NT 6.1/.test(userAgent)) osVersion = "Windows 7";
    else if (/Mac OS X/.test(userAgent)) {
      const match = userAgent.match(/Mac OS X (\d+[\._]\d+[\._]?\d*)/);
      if (match) osVersion = `macOS ${match[1].replace(/_/g, ".")}`;
    } else if (/Android/.test(userAgent)) {
      const match = userAgent.match(/Android\s([0-9\.]*)/);
      if (match) osVersion = `Android ${match[1]}`;
    } else if (/iPhone OS/.test(userAgent)) {
      const match = userAgent.match(/iPhone OS (\d+[\._]\d+)/);
      if (match) osVersion = `iOS ${match[1].replace(/_/g, ".")}`;
    }

    return osVersion;
  }

  function getDeviceType(userAgent: string) {
    if (/android/i.test(userAgent)) return "android";
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream)
      return "ios";
    return "browser";
  }

  function getModel(userAgent: string) {
    let model = "Unknown Model";

    if (/iPhone/.test(userAgent)) model = "iPhone";
    else if (/iPad/.test(userAgent)) model = "iPad";
    else if (/Macintosh/.test(userAgent)) model = "Mac";
    else if (/Windows NT/.test(userAgent)) model = "Windows PC";
    else if (/Android/.test(userAgent)) {
      const match = userAgent.match(/Android.*;\s([a-zA-Z0-9\s]+)\sBuild/);
      if (match) model = match[1];
    }

    return model;
  }

  const handleCloseUnderMaintenance = () => {
    setErrorMessage("");
  };

  return (
    <>
      <UnderMaintenance
        errorType={errorMessage}
        onClose={handleCloseUnderMaintenance}
      />

      <div className="account !max-w-full w-full overflow-x-hidden pb-10 flex flex-col">
        <SvgLogo className="mx-auto mt-0 text-center max-w-52 lg:mt-16" />
        <h2 className="!mt-10">Welcome to PropNerd!</h2>
        <p>
          Thank you for embarking on your investment journey with us. At
          PropNerd, we're dedicated to fostering a secure, transparent, and
          seamless environment for our valued investors. To ensure the highest
          standards of safety and compliance, we adhere to rigorous Know Your
          Customer (KYC) and Anti-Money Laundering (AML) protocols.
        </p>

        <div
          ref={formSectionRef}
          className="flex flex-col flex-1 mb-5 stepContent"
        >
          <h3 className="text-[1.2rem] md:text-[1.5rem] text-bold text-center tracking-[-0.2px]">
            Register as an Investor
          </h3>

          <ProgressSteps
            steps={steps}
            currentStep={currentStep}
            direction="horizontal"
            hideNavigation
            onStepChange={setCurrentStep}
          />
          <form className="gap" onSubmit={handleSubmit}>
            {!registerData ? (
              <>
                {currentStep === 1 && (
                  <>
                    <div className="gap name-container">
                      <div className="flex flex-col flex-1">
                        <label htmlFor="first-name">First Name</label>
                        <input
                          type="text"
                          id="first-name"
                          name="first-name"
                          data-test="first-name"
                          placeholder="First Name"
                          autoFocus
                          onChange={(e) => {
                            setFirstName(e.currentTarget.value);
                            setErrorMessage("");
                          }}
                          value={firstName}
                        />
                      </div>
                      <div className="flex flex-col flex-1">
                        <label htmlFor="last-name">Last Name</label>
                        <input
                          type="text"
                          id="last-name"
                          name="last-name"
                          data-test="last-name"
                          placeholder="Last Name"
                          onChange={(e) => {
                            setLastName(e.currentTarget.value);
                            setErrorMessage("");
                          }}
                          value={lastName}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        data-test="email"
                        placeholder="johnsmith@yahoo.com"
                        onChange={(e) => {
                          setEmail(e.currentTarget.value);
                          setErrorMessage("");
                        }}
                        value={email}
                      />
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="password">Password</label>
                      <div className="password-container">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          value={password}
                          name="password"
                          data-test="password"
                          placeholder="********"
                          onChange={(e) => {
                            setPassword(e.currentTarget.value);
                            setErrorMessage("");
                          }}
                          className="password-input"
                        />
                        <button
                          type="button"
                          className="password-toggle-button"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <EyeIcon className="icon" />
                          ) : (
                            <EyeSlashIcon className="icon" />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="phone">Phone</label>
                      <PhoneInputWithCountrySelect
                        defaultCountry="GB"
                        placeholder="Please enter your phone number"
                        id="phone"
                        name="phone"
                        data-test="phone"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e ? e.toString() : "");
                          setErrorMessage("");
                        }}
                      />
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="country">Country of Residence</label>
                      <select
                        id="country"
                        name="country"
                        data-test="country-select"
                        onChange={(e) => {
                          setCountry(e.currentTarget.value);
                          setErrorMessage("");
                        }}
                      >
                        {Countries.map((country, index) => (
                          <option
                            key={`${country.code}-${index}`}
                            value={country.code}
                          >
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {infoMessage && (
                      <div className="info-message !mt-5">{infoMessage}</div>
                    )}
                    {errorMessage && errorMessage !== "UNDER_MAINTENANCE" && (
                      <div className="error-message !mt-5">{errorMessage}</div>
                    )}

                    <div className="actions">
                      <div
                        data-test="continue-btn"
                        className="btn btn-primary btn-square btn-full-width"
                        style={{
                          height: "3rem",
                          cursor:
                            isValidating || validationLoading
                              ? "not-allowed"
                              : "pointer",
                          background:
                            isValidating || validationLoading
                              ? "var(--light-grey)"
                              : "var(--blue)",
                          color:
                            isValidating || validationLoading
                              ? "var(--dark-grey)"
                              : "var(--white)",
                        }}
                        onClick={validatePersonalData}
                      >
                        {isValidating ? "Validating..." : "Continue"}
                      </div>
                    </div>

                    <div className="flex justify-center text-center">
                      <Link
                        to={AppRoute.AccountLogin}
                        className="text-lg font-bold text-primary hover:text-deep-blue"
                      >
                        <span className="text-lg">
                          Already have an account?{" "}
                        </span>
                        <span className="text-lg font-semibold transform text-primary hover:underline">
                          Login
                        </span>
                      </Link>
                    </div>
                  </>
                )}

                {currentStep === 2 && (
                  <>
                    <div className="sliderSecion">
                      <RangeSlider
                        title="Overall Investing Approach"
                        startTitle="Conservative"
                        midTitle="Medium"
                        endTitle="Aggressive"
                        isOverall={true}
                        onValueChange={onAggressionChange}
                        data-test="range-slider-overall-investing"
                      />
                      <div className="divider-dark"></div>
                      <RangeSlider
                        title="Investment Duration Plan"
                        startTitle="2 Years"
                        endTitle="7 Years"
                        isOverall={false}
                        onValueChange={onDurationChange}
                        data-test="range-slider-investment-duration"
                      />
                    </div>

                    <div className="actions">
                      <div
                        data-test="back-btn"
                        className="btn btn-white btn-square btn-full-width"
                        style={{ height: "3rem" }}
                        onClick={() =>
                          setCurrentStep((prevStep) => prevStep - 1)
                        }
                      >
                        Back
                      </div>
                      <div
                        data-test="continue-btn"
                        className="btn btn-primary btn-square btn-full-width"
                        style={{ height: "3rem" }}
                        onClick={() =>
                          setCurrentStep((prevStep) => prevStep + 1)
                        }
                      >
                        Continue
                      </div>
                    </div>
                  </>
                )}

                {currentStep === 3 && (
                  <>
                    <div className="terms-text-container">
                      <TermsText />
                    </div>
                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        id="over18"
                        name="over18"
                        data-test="over18"
                        checked={isChecked.over18}
                        onChange={handleCheckboxChange}
                        className="custom-checkbox"
                      />
                      <label htmlFor="over18" className="checkbox-label">
                        I am over the age of 18.
                      </label>
                    </div>

                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        id="qualifiedInvestor"
                        name="qualifiedInvestor"
                        data-test="qualified-investor"
                        checked={isChecked.qualifiedInvestor}
                        onChange={handleCheckboxChange}
                        className="custom-checkbox"
                      />
                      <label
                        htmlFor="qualifiedInvestor"
                        className="checkbox-label"
                      >
                        I confirm I am a suitably qualified investor.
                        <a
                          href={`${websiteUrl}/qualified-investor`}
                          className="learn-more-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn more
                        </a>
                      </label>
                    </div>

                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        id="termsAgreed"
                        name="termsAgreed"
                        data-test="terms-agreed"
                        checked={isChecked.termsAgreed}
                        onChange={handleCheckboxChange}
                        className="custom-checkbox"
                      />
                      <label htmlFor="termsAgreed" className="checkbox-label">
                        I agree to the Terms of Service.
                        <a
                          href={`${websiteUrl}/terms`}
                          className="learn-more-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Service
                        </a>
                      </label>
                    </div>
                    {infoMessage && (
                      <div className="info-message !mt-5">{infoMessage}</div>
                    )}
                    {errorMessage && errorMessage !== "UNDER_MAINTENANCE" && (
                      <div className="error-message !mt-5">{errorMessage}</div>
                    )}

                    <div className="actions">
                      <div
                        data-test="back-btn"
                        className="btn btn-white btn-square btn-full-width"
                        style={{ height: "3rem" }}
                        onClick={() =>
                          setCurrentStep((prevStep) => prevStep - 1)
                        }
                      >
                        Back
                      </div>
                      <button
                        type="submit"
                        data-test="register-btn"
                        className="btn btn-primary btn-square btn-full-width"
                        style={{ height: "3rem" }}
                        disabled={loading}
                      >
                        {loading ? "Saving" : "Register"}
                      </button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
