import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  InvestorInvite,
  InvitationStatus,
  User,
} from "../../__generated__/graphql";
import ShareButtons from "../../pages/vault/share-buttons";
import { MUTATION_SEND_INVITE } from "../../graphql/mutations/send-invite";
import { useEffect, useState } from "react";
import { QUERY_GET_ME } from "../../graphql/queries/get-me";
import { QUERY_GET_INVESTOR_INVITES } from "../../graphql/queries/get-invites";
import { ReactComponent as ShareLogo } from "../../assets/svgs/share_arrow.svg";
import cashImage from "../../assets/img/referral/cash.png";
import jettImage from "../../assets/img/referral/jett.png";
import tokenImage from "../../assets/img/referral/token.png";
import arrowUpImage from "../../assets/img/referral/arrow-up.png";
import { processSysConfigs } from "../../utils/config.util";
import { QUERY_GET_SYS_CONFIG } from "../../graphql/queries/get-config";

interface InvitesModalProps {
  isOpen: boolean;
  onClose: () => void;
  isFirstLogin?: boolean;
}

export const InvitesModal = ({
  isOpen,
  onClose,
  isFirstLogin,
}: InvitesModalProps) => {
  const {
    data: user,
    loading,
    error,
    refetch,
  } = useQuery<{ me: User }>(QUERY_GET_ME, { fetchPolicy: "network-only" });
  const [
    sendInvite,
    { data: sendInviteData, loading: inviteSending, error: inviteError },
  ] = useMutation(MUTATION_SEND_INVITE, {
    fetchPolicy: "network-only",
  });
  const [getInvestorInvites, { data: invitesData }] = useLazyQuery(
    QUERY_GET_INVESTOR_INVITES,
    { fetchPolicy: "network-only" }
  );
  const [inviteEmail, setInviteEmail] = useState<string | undefined>(undefined);
  const [showInvitedMessage, setShowInvitedMessage] = useState(false);
  const [inviteErrorMessage, setInviteErrorMessage] = useState<
    string | undefined
  >("");
  const { data: configData } = useQuery(QUERY_GET_SYS_CONFIG, {
    fetchPolicy: "network-only",
  });
  const [referralRewardAmount, setReferralRewardAmount] = useState(0);
  const [allowGibberishEmail, setAllowGibberishEmail] = useState(false);
  const [showRewards, setShowRewards] = useState(false);
  const referralCode = user?.me.investorProfile?.coupon?.code || " ";
  const inviteLink = `${process.env.REACT_APP_WEBSITE_URL}?promo=${referralCode}`;
  const message = `Join me and thousands of investors making money with PropNerd. Sign up with my link below: ${inviteLink}`;

  useEffect(() => {
    if (configData) {
      const processedConfigs = processSysConfigs(configData.getAllConfigs);
      setReferralRewardAmount(processedConfigs.referralRewardAmount || 10000);
      setAllowGibberishEmail(processedConfigs.allowGibberishEmail || false);
    }
  }, [configData]);

  useEffect(() => {
    if (inviteError) {
      setInviteErrorMessage(inviteError.message);
    }
    if (sendInviteData && sendInviteData.sendInvite?.status === "error") {
      setInviteErrorMessage(sendInviteData.sendInvite.message);
    }
    if (sendInviteData && sendInviteData.sendInvite?.status === "success") {
      setInviteEmail("");
      setShowInvitedMessage(true);
    }
  }, [inviteError, sendInviteData?.sendInvite?.status]);

  useEffect(() => {
    getInvestorInvites();
    if (isFirstLogin) {
      setShowRewards(true);
    }
  }, []);

  const validateEmail = (email: string) => {
    const basicRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (allowGibberishEmail) {
      return basicRegex.test(email);
    }

    const strictRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const domain = email.split("@")[1];

    if (
      !domain ||
      domain.includes("..") ||
      domain.startsWith(".") ||
      domain.endsWith(".")
    ) {
      return false;
    }

    return strictRegex.test(email);
  };

  const handleSendInvite = async () => {
    setInviteErrorMessage("");

    if (!validateEmail(inviteEmail || "")) {
      setInviteErrorMessage("Invalid email address");
      return;
    }

    try {
      await sendInvite({
        variables: { invitedEmail: inviteEmail },
      });

      getInvestorInvites();
    } catch (err: any) {
      setShowInvitedMessage(false);
      setInviteErrorMessage(err.message || "An error occurred");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-gray-900 bg-opacity-50 ">
      <div className="w-[95%] lg:w-3/5 xl:w-2/5 px-3 py-5 lg:px-6 lg:py-8 bg-white rounded-lg shadow-lg max-h-[95%]">
        <div className="flex items-center justify-between mb-2 lg:mb-4 pb-2 lg:pb-4 border-b border-[#E5E5E5]">
          <h2 className="text-2xl font-bold text-deep-blue tracking-[-0.6px]">
            {showRewards
              ? `Invite Friends & Earn £${referralRewardAmount / 100}`
              : "Invite Friend"}
          </h2>
          <button
            onClick={() => {
              onClose();
              setInviteErrorMessage("");
              setShowInvitedMessage(false);
              setShowRewards(false);
            }}
            className="text-2xl text-gray-500 text- hover:text-gray-800"
          >
            &times;
          </button>
        </div>

        {showRewards && (
          <div className="p-0">
            <div className="flex flex-col gap-4">
              <button
                className="flex gap-2 py-6 font-semibold btn btn-primary my-2"
                onClick={() => {
                  setShowRewards(false);
                }}
              >
                Invite Friends
                <ShareLogo />
              </button>
              <div className="flex flex-col my-4 gap-2">
                <h3 className="text-xl font-bold text-deep-blue tracking-[-0.3px]">
                  Reward You Will Get
                </h3>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-3 items-center bg-[#F1F2F3] rounded-xl p-2">
                    <div className="border border-[#EBEBEB] bg-[#FAFAFA] rounded-full h-[4rem] w-[4rem] flex items-center justify-center">
                      <img
                        src={cashImage}
                        alt="Cash"
                        className="h-1/2 w-1/2 object-contain"
                      />
                    </div>
                    <div>
                      <p className="text-lg font-semibold text-deep-blue tracking-[-0.2px]">
                        £{referralRewardAmount / 100} Cashback Reward
                      </p>
                      <p className="text-lg font-semibold text-[#6E7B94] tracking-[-0.3px]">
                        Earn £{referralRewardAmount / 100} for every successful
                        referral.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!showRewards && (
          <div className="flex-grow overflow-hidden flex flex-col">
            <div className="flex flex-row justify-between">
              <p className="mb-2 text-sm font-bold text-deep-blue">
                Invite Email
              </p>
              {showInvitedMessage &&
                !inviteEmail &&
                sendInviteData?.sendInvite?.status === "success" && (
                  <p className="text-green-500 px-4">Invited</p>
                )}
            </div>
            <div className="flex items-center space-x-4">
              <input
                type="email"
                placeholder="Enter Email Address"
                value={inviteEmail}
                readOnly={false}
                onChange={(e) => {
                  setInviteEmail(e.target.value);
                  setInviteErrorMessage("");
                  setShowInvitedMessage(false);
                }}
                className="flex-grow px-4 py-4 text-sm font-semibold border border-[#EBEBEB] bg-[#FAFAFA] rounded-lg text-deep-blue"
              />
              <button
                className="h-full text-sm font-semibold btn btn-primary rounded-xl"
                onClick={handleSendInvite}
                disabled={inviteSending}
              >
                Send Invite
              </button>
            </div>
            {inviteErrorMessage &&
              inviteErrorMessage !== "UNDER_MAINTENANCE" && (
                <div className="max-w-md px-4 pt-2 text-red-500">
                  {inviteErrorMessage}
                </div>
              )}
            <div className="space-y-2 border-b border-[#E5E5E5] pb-4 max-h-[18rem] overflow-y-auto relative custom-scrollbar mt-4">
              {invitesData?.investorInvites.length > 0 ? (
                invitesData?.investorInvites.map(
                  (investorInvited: InvestorInvite, index: number) => (
                    <div
                      key={index}
                      className="flex items-center justify-between py-2 rounded-lg"
                    >
                      <div className="flex items-center space-x-4">
                        <div className="flex items-center justify-center w-8 h-8 bg-[#FAFAFA] border border-[#EBEBEB] text-md lg:text-lg font-semibold rounded-full text-deep-blue">
                          {investorInvited?.invitedEmail
                            ?.charAt(0)
                            .toUpperCase()}
                        </div>
                        <span className="text-sm font-bold text-deep-blue">
                          {investorInvited?.invitedEmail}
                        </span>
                      </div>
                      {investorInvited.status === InvitationStatus.Pending && (
                        <span className="text-sm font-semibold bg-[rgba(61,_133,_5,_0.07)] px-2 py-1 rounded-md">
                          Invite Sent
                        </span>
                      )}
                      {investorInvited.status === InvitationStatus.Accepted && (
                        <span className="text-sm font-semibold text-[#34C759] bg-[rgba(61,_133,_5,_0.07)] px-2 py-1 rounded-md">
                          Invitation Accepted
                        </span>
                      )}
                      {investorInvited.status === InvitationStatus.Invested && (
                        <span className="text-sm font-semibold text-[#34C759] bg-[rgba(61,_133,_5,_0.07)] px-2 py-1 rounded-md">
                          Rewarded
                        </span>
                      )}
                    </div>
                  )
                )
              ) : (
                <p className="text-gray-600">No referrals yet.</p>
              )}
            </div>
            <ShareButtons
              referralCode={referralCode}
              inviteLink={inviteLink}
              message={message}
            />

            {/* <div className="space-y-4">
                  {referralData.getReferralsByReferrer.length > 0 ? (
                    referralData.getReferralsByReferrer.map((referral: Referral, index: number) => (
                      <div
                        key={index}
                        className="flex items-center justify-between py-2 rounded-lg"
                      >
                        <div className="flex items-center space-x-4">
                          <div className="flex items-center justify-center w-8 h-8 bg-[#FAFAFA] border border-[#EBEBEB] text-lg font-semibold rounded-full text-deep-blue">
                            {referral?.referredInvestor?.user.email.charAt(0).toUpperCase()}
                          </div>
                          <span className="text-sm font-bold text-deep-blue">
                            {referral?.referredInvestor?.user.email}
                          </span>
                        </div>
                        <span
                          className="text-sm font-semibold text-[#34C759] bg-[rgba(61,_133,_5,_0.07)] p-2 rounded-md">
                          Invitation Accepted
                        </span>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-600">No referrals yet.</p>
                  )}
                </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
