import { useState, useEffect, useContext } from "react";
import { getToken } from "firebase/messaging";
import { useMutation } from "@apollo/client";
import {
  SAVE_FCM_TOKEN,
  UPDATE_HIDE_NOTIFICATION_BAR,
} from "../../graphql/mutations/notification";
import {
  getMessaging,
  firebaseApp,
  isSupported,
} from "../../config/firebase.config";
import { NotificationContext } from "../../context/Notification";
import WarningImg from "../../assets/svgs/warning-error.svg";

const NotificationTopBar = () => {
  const {
    turnNotificationsOn,
    hideNotificationBar,
    loading,
    setTurnNotificationsOn,
    setHideNotificationBar,
  } = useContext(NotificationContext);
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [showNotificationBar, setShowNotificationBar] = useState(true);
  const [notificationDenied, setNotificationDenied] = useState(false);
  const [buttonLoadingMessage, setButtonLoadingMessage] = useState<string>();

  const [saveFcmToken] = useMutation(SAVE_FCM_TOKEN, { errorPolicy: "all" });
  const [updateHideNotificationBar] = useMutation(UPDATE_HIDE_NOTIFICATION_BAR);

  useEffect(() => {
    checkSupport();
  }, []);

  useEffect(() => {
    if (!loading && (turnNotificationsOn || hideNotificationBar)) {
      setPermissionGranted(true);
      setShowNotificationBar(false);
    }
  }, [turnNotificationsOn, hideNotificationBar, loading]);

  async function checkSupport() {
    if (!(await isSupported())) {
      console.error("Firebase messaging is not supported on this browser.");
      setShowNotificationBar(false);
    }
  }

  async function requestPermission() {
    try {
      setButtonLoadingMessage("Asking for permission...");
      const messaging = getMessaging(firebaseApp);
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        setButtonLoadingMessage("Registering device...");
        const registration = await navigator.serviceWorker.ready; // Wait for service worker to be ready
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
          serviceWorkerRegistration: registration,
        });

        const deviceId = localStorage.getItem("deviceId");

        if (token) {
          setButtonLoadingMessage("Almost done...");
          await saveFcmToken({
            variables: {
              fcmToken: token,
              turnNotificationsOn: true,
              deviceId: deviceId,
            },
          });
          setTurnNotificationsOn(true); // Update global state
        }
        setPermissionGranted(true);
        setShowNotificationBar(false);
      } else {
        setNotificationDenied(true); // Show custom message if permission is denied
      }
      setButtonLoadingMessage(undefined);
    } catch (error) {
      setButtonLoadingMessage(undefined);
      console.error("Failed to get permission or token:", error);
    }
  }

  async function hideNotification() {
    try {
      const deviceId = localStorage.getItem("deviceId");

      await updateHideNotificationBar({
        variables: { hideNotificationBar: true, deviceId: deviceId },
      });
      setHideNotificationBar(true);
      setShowNotificationBar(false);
    } catch (error) {
      console.error("Failed to update hide notification preference:", error);
    }
    setShowNotificationBar(false);
  }

  if (
    loading ||
    turnNotificationsOn ||
    hideNotificationBar ||
    permissionGranted ||
    !showNotificationBar
  ) {
    return null; // Do not show the bar if permission is granted or user opted to hide it or data is loading
  }

  return (
    <div className="bg-[#FDF5EC] border-[#F8D5AE] border-1.5 rounded-lg text-sm text-black text-center mt-0 mb-8 p-2 lg:p-8 mr-10 lg:mr-0">
      {notificationDenied ? (
        <p>
          You have denied notification permission. Please enable notifications
          in your browser settings if you change your mind.
          <button
            onClick={() => setShowNotificationBar(false)}
            className="underline text-red-600"
          >
            Close
          </button>
        </p>
      ) : (
        <>
          <div className="flex-col p-4 lg:p-0">
            <div className="flex lg:flex-row gap-2 mb-4">
              <img src={WarningImg} alt="Warning" />
              <p className="font-bold text-base text-start">
                Stay Updated with Push Notifications
              </p>
            </div>
            <div className="flex flex-col lg:flex-row justify-between align-middle text-start ">
              <p className="lg:w-1/2">
                You haven't enabled push notifications yet. Turn them on to
                receive real-time updates, important alerts, and stay connected
                with everything that matters to you.
              </p>
              <div className="flex gap-4 lg:w-1/3 flex-wrap align-middle lg:justify-end lg:mt-0 mt-4">
                <button onClick={hideNotification}>
                  Don't want to see it again
                </button>
                <button
                  onClick={requestPermission}
                  disabled={!!buttonLoadingMessage}
                  className="rounded-lg btn px-4 py-6"
                >
                  {!buttonLoadingMessage ? "Enable Now" : buttonLoadingMessage}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NotificationTopBar;
