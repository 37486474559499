import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_GET_TWOFA_METHODS } from "../../graphql/queries/twofa-methods";
import { UPDATE_TWOFA_METHOD } from "../../graphql/mutations/twofa_methods"; // Import the mutation
import { DELETE_TWOFA_METHOD } from "../../graphql/mutations/twofa_methods"; // Import the delete mutation
import RegisterWebAuthn from "../../components/modal/register-webAuthn";
import { Sidebar } from "../../components/sidebar/sidebar";
import { KycCheck } from "../account/kyc/kyc";
import { GlobalErrorHandler } from "../../components/error/global_error.component";
import { Button, CircularProgress, Alert, Switch } from "@mui/material"; // Using Material-UI for better UI components

export const TwoFA: React.FC = () => {
  const {
    data: authMethods,
    loading: loadingAuthMethods,
    error: errorLoadingAuthMethods,
    refetch: reloadAuthMethods,
  } = useQuery(QUERY_GET_TWOFA_METHODS, { fetchPolicy: "network-only" });

  const [updateTwofaMethod] = useMutation(UPDATE_TWOFA_METHOD); // Initialize the mutation
  const [deleteTwofaMethod] = useMutation(DELETE_TWOFA_METHOD); // Initialize the delete mutation

  const [showRegisterModal, setShowRegisterModal] = useState(false);

  interface TwoFAMethod {
    methodId: string;
    methodType: string;
    isEnabled: boolean;
    isPrimary: boolean;
    addedAt: Date; // Add createdAt field
  }
  const [methods, setMethods] = useState<TwoFAMethod[]>([]);

  useEffect(() => {
    if (authMethods && authMethods.getTwofaMethods) {
      // Sort: Keep primary first, preserve order of others
      const sortedMethods = [...authMethods.getTwofaMethods].sort((a, b) => {
        if (b.isPrimary && !a.isPrimary) return 1;
        if (a.isPrimary && !b.isPrimary) return -1;
        return a.methodId.localeCompare(b.methodId); // Stable sorting for non-primary
      });
      setMethods(sortedMethods);
    }
  }, [authMethods]);

  const handleRegisterSuccess = () => {
    reloadAuthMethods(); // Reload the methods after successful registration
    setShowRegisterModal(false);
  };

  const handleAddNewMethod = () => {
    setShowRegisterModal(true);
  };

  const handleToggleMethod = async (methodId: string) => {
    try {
      // Optimistic UI update
      setMethods((prevMethods) =>
        prevMethods.map((m) =>
          m.methodId === methodId ? { ...m, isEnabled: !m.isEnabled } : m
        )
      );

      // Call the API to persist the change
      await updateTwofaMethod({
        variables: {
          methodId,
          isEnabled: !methods.find((m) => m.methodId === methodId)?.isEnabled,
        },
        refetchQueries: [{ query: QUERY_GET_TWOFA_METHODS }],
      });

      // Ensure fresh data is fetched
      reloadAuthMethods();
    } catch (error) {
      console.error("Error updating 2FA method:", error);

      // Revert optimistic update on error
      setMethods((prevMethods) =>
        prevMethods.map((m) =>
          m.methodId === methodId ? { ...m, isEnabled: !m.isEnabled } : m
        )
      );
    }
  };

  const handleDeleteMethod = async (methodId: string) => {
    try {
      // Optimistic UI update
      setMethods((prevMethods) =>
        prevMethods.filter((m) => m.methodId !== methodId)
      );

      // Call the API to delete the method
      await deleteTwofaMethod({
        variables: { methodId },
        refetchQueries: [{ query: QUERY_GET_TWOFA_METHODS }],
      });

      // Ensure fresh data is fetched
      reloadAuthMethods();
    } catch (error) {
      console.error("Error deleting 2FA method:", error);

      // Revert optimistic update on error (you might want to handle this differently)
      reloadAuthMethods();
    }
  };

  if (loadingAuthMethods) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (errorLoadingAuthMethods) {
    return (
      <div style={{ padding: "20px" }}>
        <Alert severity="error">
          Error loading 2FA methods. Please try again later.
        </Alert>
      </div>
    );
  }

  return (
    <>
      <KycCheck />
      <GlobalErrorHandler />
      <Sidebar />

      <section className="content portfolio">
        <h1 className="font-sans text-deep-blue tracking-normal text-3xl mb-8 font-bold leading-normal">
          2FA Management
        </h1>

        <div
          className="card flex flex-col justify-center p-6"
          style={{ maxWidth: 600 }}
        >
          <div className="mb-4">
            <p className="font-semibold text-lg">Secure Your Account</p>
            <p className="text-gray-700">
              Two-Factor Authentication (2FA) adds an extra layer of security to
              your account. By enabling 2FA, you ensure that only you can access
              your account, even if your password is compromised.
            </p>

            <p className="mt-6 font-semibold text-lg">
              Registered 2FA Methods:
            </p>
            {methods.length > 0 ? (
              <ul className="list-none space-y-4 mt-2">
                {methods.map((method) => (
                  <li
                    key={method.methodId}
                    className="flex items-center justify-between border-b pb-3 text-gray-700"
                  >
                    <div>
                      <span className="capitalize">
                        Passkey -{" "}
                        <span className="text-gray-500">
                          {method.isEnabled ? "Enabled" : "Disabled"}
                        </span>
                        {method.isPrimary && (
                          <span className="text-yellow-600"> (Primary)</span>
                        )}
                      </span>
                      <br />
                      <small className="text-gray-400">
                        Added on:{" "}
                        {new Date(method.addedAt).toLocaleString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </small>
                    </div>
                    <div className="flex items-center">
                      <Switch
                        checked={method.isEnabled}
                        onChange={() => handleToggleMethod(method.methodId)}
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "#F5CA48", // Custom handle color when checked (yellow)
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "#F5CA48", // Custom track color when checked
                            },
                          "& .MuiSwitch-track": {
                            backgroundColor: "#ccc", // Default track color when unchecked
                          },
                        }}
                      />
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteMethod(method.methodId)}
                        style={{ marginLeft: "10px" }}
                      >
                        Delete
                      </Button>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="mt-2 text-gray-500">
                No 2FA methods registered yet.
              </p>
            )}

            <div className="mt-6">
              <button onClick={handleAddNewMethod} className="btn">
                Add New 2FA Method
              </button>
            </div>
          </div>
        </div>

        {showRegisterModal && (
          <RegisterWebAuthn
            open={showRegisterModal}
            onClose={() => setShowRegisterModal(false)}
            onSuccess={handleRegisterSuccess}
          />
        )}
      </section>
    </>
  );
};
