import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { AppRoutes } from "./routes";
import "./assets/styles/index.css";
import { Apollo } from "./components/apollo/apollo.component";
import { SettingsProvider } from "./context/settings";
import {
  getMessaging,
  firebaseApp,
  isSupported as isFirebaseSupported,
} from "./config/firebase.config";
import { onMessage } from "firebase/messaging";
import { NotificationProvider } from "./context/Notification";
import { getAnalytics, logEvent } from "firebase/analytics";
import Intercom from "@intercom/messenger-js-sdk";

Intercom({
  app_id: "onnd9j9n",
  hide_default_launcher: true,
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Apollo>
    <SettingsProvider appVersion="1.0.0">
      <NotificationProvider>
        <AppRoutes />
      </NotificationProvider>
    </SettingsProvider>
  </Apollo>
);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", async () => {
    try {
      const registration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js"
      );
      console.log(
        "ServiceWorker registration successful with scope: ",
        registration.scope
      );

      if (await isFirebaseSupported()) {
        const messaging = getMessaging(firebaseApp);
        onMessage(messaging, (payload) => {
          console.log("Message received. ", payload);
        });
      } else {
        console.log("Firebase messaging is not supported on this browser.");
      }
    } catch (error) {
      console.error("ServiceWorker registration failed: ", error);
    }
  });
} else {
  console.warn("Service workers are not supported in this browser.");
}
const analytics = getAnalytics(firebaseApp); // Initialize Analytics here
logEvent(analytics, "page_view"); // This tracks a page view event

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
