import { gql } from "@apollo/client";

export const QUERY_GET_INVESTOR_INVITES = gql`
  query GetInvestorInvites {
    investorInvites {
      id
      invitedEmail
      status
    }
  }
`;
