import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { AccountLoginPage } from "./pages/account/login/login";
import { AccountForgetPage } from "./pages/account/forget/forget";
import { AccountResetPage } from "./pages/account/reset/reset";
import { CartPage } from "./pages/cart/cart";
import { HelpPage } from "./pages/help/help";
import { PortfolioPage } from "./pages/portfolio/portfolio";
import { PropertiesPage } from "./pages/properties/properties";
import { VaultPage } from "./pages/vault/vault";
import { ProfilePage } from "./pages/profile/profile";
import { AnalyticsPage } from "./pages/analytics/analytics";
import { Error404Page } from "./pages/error/error404";
import { OtpPage } from "./pages/account/otp/otp";
import { PropertyDetailsPage } from "./pages/property/property-detail";
import { StripeRedirect } from "./pages/vault/stripe-redirect";
import { AccountRegisterPage } from "./pages/account/register/register";
import { TwoFA } from "./pages/twoFA/twoFA";
import { GlobalAppFeatures } from "./components/global-app-features";

export enum AppRoute {
  Home = "/",
  Properties = "/properties",
  PropertyDetails = "/property-details",
  Vault = "/vault",
  StripeRedirect = "/stripe-redirect",
  Portfolio = "/portfolio",
  Cart = "/cart",
  Analytics = "/analytics",
  Profile = "/profile",
  Help = "/help",
  ProfileAccount = "/profile/account",
  ProfilePreferences = "/profile/preferences",
  AccountRegister = "/account/register",
  AccountLogin = "/account/login",
  AccountReset = "/account/reset",
  AccountForget = "/account/forget",
  LoginVefitication = "/account/verify",
  TwoFA = "/twoFA",
}

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route
      path={AppRoute.Home}
      element={<Navigate to={AppRoute.Properties} />}
    />,
    <Route path={AppRoute.Properties} element={<PropertiesPage />} />,
    <Route path={AppRoute.PropertyDetails} element={<PropertyDetailsPage />} />,
    <Route path={AppRoute.Vault} element={<VaultPage />} />,
    <Route path={AppRoute.StripeRedirect} element={<StripeRedirect />} />,
    <Route path={AppRoute.Portfolio} element={<PortfolioPage />} />,
    <Route path={AppRoute.Cart} element={<CartPage />} />,
    <Route path={AppRoute.Analytics} element={<AnalyticsPage />} />,
    <Route path={AppRoute.Profile} element={<ProfilePage />} />,
    <Route path={AppRoute.Help} element={<HelpPage />} />,
    <Route path={AppRoute.ProfileAccount} element={<ProfilePage />} />,
    <Route path={AppRoute.AccountRegister} element={<AccountRegisterPage />} />,
    <Route path={AppRoute.AccountLogin} element={<AccountLoginPage />} />,
    <Route path={AppRoute.AccountForget} element={<AccountForgetPage />} />,
    <Route path={AppRoute.AccountReset} element={<AccountResetPage />} />,
    <Route path={AppRoute.LoginVefitication} element={<OtpPage />} />,
    <Route path={AppRoute.TwoFA} element={<TwoFA />} />,
    <Route path="*" element={<Error404Page />} />,
  ])
);

export const AppRoutes = () => (
  <>
    <RouterProvider router={router} />
    {/* <GlobalAppFeatures /> */}
  </>
);
