import { gql } from "@apollo/client";

export const MUTATION_SEND_INVITE = gql`
  mutation SendInvite($invitedEmail: String!) {
    sendInvite(invitedEmail: $invitedEmail) {
      status
      message
      invites {
        id
        invitedEmail
        status
      }
    }
  }
`;
