import React, { useState } from "react";
import WhatsAppIcon from "../../assets/svgs/whatsapp.svg";
import MessengerIcon from "../../assets/svgs/messenger.svg";
import LinkIcon from "../../assets/svgs/link.svg";

interface ShareButtonsProps {
  referralCode: string;
  inviteLink: string;
  message: string;
}

const ShareButtons: React.FC<ShareButtonsProps> = ({
  referralCode,
  inviteLink,
  message,
}) => {
  const [showMessage, setShowMessage] = useState(false);

  // Function to handle copying the link
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(inviteLink)
      .then(() => {
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 2000); // Hide the message after 2 seconds
      })
      .catch((err) => console.error("Failed to copy link:", err));
  };

  return (
    <div className="space-y-4 mt-4">
      <p className="mb-2 text-sm font-bold text-deep-blue">Share Via</p>
      <div className="flex items-center my-2 space-x-2 lg:space-x-4">
        {/* WhatsApp Button */}
        <div className="flex flex-col items-center">
          <a
            href={`https://wa.me/?text=${encodeURIComponent(message)}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center w-12 h-12 bg-deep-blue rounded-full shadow-md hover:bg-green-600"
          >
            <img src={WhatsAppIcon} alt="WhatsApp" className="w-6 h-6" />
          </a>
          <span className="mt-2 text-sm font-bold">WhatsApp</span>
        </div>

        {/* Messenger Button */}
        <div className="flex flex-col items-center">
          <a
            href={`https://www.facebook.com/dialog/send?app_id=YOUR_APP_ID&link=${encodeURIComponent(inviteLink)}`}
            target="_blank"
            className="flex items-center justify-center w-12 h-12 bg-deep-blue rounded-full shadow-md hover:bg-blue-600"
          >
            <img src={MessengerIcon} alt="Messenger" className="w-6 h-6" />
          </a>
          <span className="mt-2 text-sm font-bold">Messenger</span>
        </div>

        {/* Copy Link Button */}
        <div className="flex flex-col items-center">
          <button
            onClick={copyToClipboard}
            className="flex items-center justify-center w-12 h-12 bg-[#FFD000] rounded-full shadow-md hover:bg-[#fcd730]"
          >
            <img src={LinkIcon} alt="Copy Link" className="w-6 h-6" />
          </button>
          <span className="mt-2 text-sm font-bold">Copy Link</span>
        </div>
        {showMessage && (
          <div className="fixed bottom-5 left-1/2 transform -translate-x-1/2 px-4 py-2 bg-gray-200 text-deep-blue rounded shadow-lg ease-in-out border border-gray-300">
            Link copied to clipboard!
          </div>
        )}
      </div>
    </div>
  );
};

export default ShareButtons;
