import { gql } from "@apollo/client";

export const MUTATION_LOGIN = gql`
  mutation Login(
    $email: String!
    $password: String!
    $isPasskey: Boolean!
    $deviceId: String!
    $deviceType: String!
    $osVersion: String!
    $model: String!
  ) {
    login(
      data: { email: $email, password: $password, isPasskey: $isPasskey }
      deviceData: {
        deviceId: $deviceId
        deviceType: $deviceType
        osVersion: $osVersion
        model: $model
      }
    ) {
      accessToken
      lastLogin
      deviceStatus
      userId
      userStatus
      kycOnRegister
    }
  }
`;
