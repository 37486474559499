import { useLazyQuery, useQuery } from "@apollo/client";
import { Sidebar } from "../../components/sidebar/sidebar";
import { QUERY_GET_PORTFOLIO_STATS } from "../../graphql/queries/get-portfolio-stats";
import {
  toCurrencyDisplay,
  toHumanReadableCurrency,
} from "../../utils/currency.util";
import { toPercentageDisplay } from "../../utils/string.util";
import { useEffect, useState } from "react";
import {
  QUERY_GET_CONFIRMED_PLEDGES,
  QUERY_GET_UPCOMING_RENTS,
} from "../../graphql/queries/get-pending-pledges";
import {
  PropertyPledge,
  PropertyPledgeStatus,
  UpcomingRentResponse,
} from "../../__generated__/graphql";
import { Link } from "react-router-dom";
import { AppRoute } from "../../routes";
import { GlobalErrorHandler } from "../../components/error/global_error.component";
import { format } from "date-fns";
import TableSkeleton from "../../components/skeleton/transactionskeleton";
import BoxSkeleton from "../../components/skeleton/vaultskeleton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";
import { KycCheck } from "../account/kyc/kyc";

interface PortfolioStats {
  portfolioValue: number;
  monthlyIncome: number;
  totalRentalIncome: number;
  totalAppreciation: number;
  numProperties: number;
  occupancyRate: number;
  annualRentYield: number;
  totalInvestment: number;
  totalInvestmentLast12Months: number;
}

const useResponsiveSize = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return {
    width: isSmallScreen ? 300 : 600,
    height: isSmallScreen ? 275 : 550,
  };
};

export function PortfolioPage() {
  const responsiveSize = useResponsiveSize();

  const { data, loading, error } = useQuery<{
    getPortfolioStats: PortfolioStats;
  }>(QUERY_GET_PORTFOLIO_STATS, { fetchPolicy: "network-only" });
  const [
    getConfirmedPledges,
    {
      data: confirmedPledgesData,
      loading: confirmedPledgesLoading,
      error: confirmedPledgesError,
    },
  ] = useLazyQuery<{ getConfirmedPledges: PropertyPledge[] }>(
    QUERY_GET_CONFIRMED_PLEDGES,
    { fetchPolicy: "network-only" }
  );
  const [
    getUpcomingRents,
    {
      data: upcomingRentsData,
      loading: upcomingRentsLoading,
      error: upcomingRentsError,
    },
  ] = useLazyQuery<{ getUpcomingRents: UpcomingRentResponse[] }>(
    QUERY_GET_UPCOMING_RENTS,
    { fetchPolicy: "network-only" }
  );

  const [limitUsed, setLimitUsed] = useState(0);
  const [limitUsedPercentage, setLimitUsedPercentage] = useState(0);
  const [availableToInvest, setAvailableToInvest] = useState(0);

  useAuthAndErrorHandling(error);
  useAuthAndErrorHandling(confirmedPledgesError);
  useAuthAndErrorHandling(upcomingRentsError);

  useEffect(() => {
    if (data) {
      setLimitUsed(data.getPortfolioStats.totalInvestment);
      setLimitUsedPercentage(
        ((data.getPortfolioStats.totalInvestment / 1500000) * 100) / 100
      );
      setAvailableToInvest(
        150000000 - data.getPortfolioStats.totalInvestmentLast12Months
      );
    }
  }, [data]);

  useEffect(() => {
    getConfirmedPledges();
    getUpcomingRents();
  }, []);

  const currentDate = new Date();
  const previousMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1
  );
  const formattedPreviousMonth = previousMonthDate.toLocaleString("default", {
    month: "short",
    year: "numeric",
  });

  return (
    <>
      <KycCheck />
      <GlobalErrorHandler />

      <Sidebar />

      <section className="content portfolio">
        <h1 className="mb-8 font-sans text-3xl font-bold leading-normal tracking-normal text-deep-blue">
          Portfolio
        </h1>
        {loading ? (
          <BoxSkeleton width={responsiveSize.width} height={100} />
        ) : (
          data && (
            <div>
              <div className="card">
                <p className="text-lg text-gray-800">Portfolio Value</p>
                <h3
                  data-test="portfolio-value"
                  className="font-sans text-4xl font-bold leading-normal tracking-normal text-deep-blue"
                >
                  {toCurrencyDisplay(data.getPortfolioStats.portfolioValue)}
                </h3>
              </div>
            </div>
          )
        )}

        <h2 className="mt-8 mb-4 font-sans text-2xl font-bold leading-normal tracking-normal text-deep-blue">
          Key financials
        </h2>
        {loading ? (
          <div className="flex flex-wrap gap-4 m-flex-col">
            <BoxSkeleton width={responsiveSize.width / 2} height={100} />
            <BoxSkeleton width={responsiveSize.width / 2} height={100} />
            <BoxSkeleton width={responsiveSize.width / 2} height={100} />
          </div>
        ) : (
          data && (
            <div className="flex flex-wrap gap-4 m-flex-col">
              <div className="flex justify-between flex-1 card">
                <div className="flex flex-col items-center justify-center gap-2 ">
                  <div className="text-lg text-yellow-500 ">
                    <i className="lni lni-coin" />
                  </div>
                  <div className="text-sm text-gray-800 ">Monthly income</div>
                </div>
                <div className="flex-col items-center justify-center gap-2 ">
                  <div
                    data-test="monthly-income"
                    className="text-lg font-semibold "
                  >
                    {toCurrencyDisplay(data.getPortfolioStats.monthlyIncome)}
                  </div>
                  <div
                    data-test="monthly-income-date"
                    className="text-sm text-gray-800 "
                  >
                    {formattedPreviousMonth}
                  </div>
                </div>
              </div>
              <div className="flex justify-between flex-1 card">
                <div className="flex flex-col items-center justify-center gap-2 ">
                  <div className="text-lg text-yellow-500 icon ">
                    <i className="lni lni-book" />
                  </div>
                  <div className="text-sm text-gray-800 ">
                    Total rental income
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center gap-2 ">
                  <div
                    data-test="total-rental-income"
                    className="text-lg font-semibold "
                  >
                    {toCurrencyDisplay(
                      data.getPortfolioStats.totalRentalIncome
                    )}
                  </div>
                  <div
                    data-test="total-rental-income-date"
                    className="text-sm text-gray-800 "
                  >
                    as of {formattedPreviousMonth}
                  </div>
                </div>
              </div>
              <div className="flex justify-between flex-1 card">
                <div className="flex flex-col items-center justify-center gap-2 ">
                  <div className="text-lg text-yellow-500 ">
                    <i className="lni lni-graph" />
                  </div>
                  <div className="text-sm text-gray-800 ">
                    Total appreciation
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center gap-2 ">
                  <div
                    data-test="total-appreciation"
                    className="text-lg font-semibold "
                  >
                    {toCurrencyDisplay(
                      data.getPortfolioStats.totalAppreciation
                    )}
                  </div>
                  <div
                    data-test="total-appreciation-date"
                    className="text-sm text-gray-800 "
                  >
                    as of {formattedPreviousMonth}
                  </div>
                </div>
              </div>
            </div>
          )
        )}

        <div className="flex flex-col gap-4 md:flex-row ">
          <div className="flex-3">
            <h2 className="mt-8 mb-4 font-sans text-2xl font-bold leading-normal tracking-normal text-deep-blue">
              Quick insights
            </h2>
            {loading ? (
              <div className="flex flex-wrap gap-4 m-flex-col">
                <BoxSkeleton width={responsiveSize.width / 2} height={100} />
                <BoxSkeleton width={responsiveSize.width / 2} height={100} />
                <BoxSkeleton width={responsiveSize.width / 2} height={100} />
              </div>
            ) : (
              data && (
                <div className="flex flex-wrap gap-4 m-flex-col">
                  <div className="flex flex-col items-center justify-center flex-1 gap-2 card ">
                    <div className="text-lg text-yellow-500 ">
                      <i className="lni lni-home" />
                    </div>
                    <div className="text-sm text-gray-800 ">
                      Number of Properties
                    </div>
                    <div
                      data-test="number-of-properties"
                      className="text-lg font-semibold "
                    >
                      {data.getPortfolioStats.numProperties}
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center flex-1 gap-2 card ">
                    <div className="text-lg text-yellow-500 ">
                      <i className="lni lni-users" />
                    </div>
                    <div className="text-sm text-gray-800 ">Occupancy rate</div>
                    <div
                      data-test="occupancy-rate"
                      className="text-lg font-semibold "
                    >
                      {toPercentageDisplay(
                        data.getPortfolioStats.occupancyRate
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center flex-1 gap-2 card ">
                    <div className="text-lg text-yellow-500 ">
                      <i className="lni lni-revenue" />
                    </div>
                    <div className="text-sm text-gray-800 ">
                      Annual appreciation
                    </div>
                    <div
                      data-test="annual-appreciation"
                      className="text-lg font-semibold "
                    >
                      {toCurrencyDisplay(
                        data.getPortfolioStats.annualRentYield
                      )}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <div className="w-full flex-2">
            <h2 className="mt-8 mb-4 font-sans text-2xl font-bold leading-normal tracking-normal text-deep-blue">
              Annual investment limit
            </h2>
            {loading ? (
              <BoxSkeleton width={responsiveSize.width} height={100} />
            ) : (
              data && (
                <div className="card">
                  <p
                    data-test="limit-used"
                    className="text-sm text-center text-gray-800 "
                  >
                    {toPercentageDisplay(limitUsedPercentage * 100)} of limit
                    used
                  </p>
                  <div className="progress">
                    <div className="baseline"></div>
                    <div
                      className="current"
                      style={{ width: `${limitUsedPercentage}%` }}
                    ></div>
                  </div>
                  <table className="w-full limit-table">
                    <tbody>
                      <tr>
                        <td className="text-sm text-gray-800 ">Annual Limit</td>
                        <td
                          data-test="annual-limit"
                          className="text-sm font-semibold text-right text-gray-800 "
                        >
                          £1,500,000
                        </td>
                      </tr>
                      <tr>
                        <td className="text-sm text-gray-800 ">
                          Invested in last 12 months
                        </td>
                        <td
                          data-test="invested-in-last-12-months"
                          className="text-sm font-semibold text-right text-gray-800 "
                        >
                          {toCurrencyDisplay(
                            data.getPortfolioStats.totalInvestment
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-sm text-gray-800 ">
                          Available to invest
                        </td>
                        <td
                          data-test="available to invest"
                          className="text-sm font-semibold text-right text-gray-800 "
                        >
                          {toCurrencyDisplay(availableToInvest)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            )}
          </div>
        </div>

        <h2 className="mt-8 mb-4 font-sans text-2xl font-bold leading-normal tracking-normal text-deep-blue">
          My Investments
        </h2>
        <table className="w-full investments card">
          <thead>
            <tr>
              <th className="font-semibold text-left border-b ">Property</th>
              <th className="font-semibold text-left border-b m-hidden">
                Location
              </th>
              <th className="font-semibold text-center border-b ">
                Investment value
              </th>
              <th className="font-semibold text-center border-b m-hidden">
                Total rental income
              </th>
              <th className="font-semibold text-left border-b ">Status</th>
            </tr>
          </thead>
          <tbody>
            {confirmedPledgesLoading && (
              <TableSkeleton width={responsiveSize.width} height={100} />
            )}
            {!confirmedPledgesLoading && !confirmedPledgesData && (
              <tr>
                <td
                  data-test="no-investments"
                  className="text-center empty"
                  colSpan={5}
                >
                  No investments
                </td>
              </tr>
            )}
            {confirmedPledgesData &&
              confirmedPledgesData.getConfirmedPledges.map((pledge) => (
                <tr data-test="my-pledge-id" key={pledge.id}>
                  <td
                    data-test="my-property-title"
                    className="px-2 py-6 text-left md:px-4 "
                  >
                    <Link
                      target="_blank"
                      to={`${AppRoute.PropertyDetails}?id=${pledge.property.id}`}
                    >
                      {pledge.property.title}
                    </Link>
                  </td>
                  <td
                    data-test="location"
                    className="px-2 py-6 text-left md:px-4 m-hidden"
                  >
                    {pledge.property.city.name}
                  </td>
                  <td
                    data-test="pledge-amount"
                    className="px-2 py-6 text-center md:pr-4 "
                  >
                    {toCurrencyDisplay(pledge.amount)}
                  </td>
                  <td
                    data-test="total-rental-income"
                    className="px-2 py-6 text-center md:pr-4 m-hidden"
                  >
                    {toCurrencyDisplay(
                      pledge.property.rents.reduce(
                        (acc, rent) => acc + rent.rent,
                        0
                      )
                    )}
                  </td>
                  <td
                    data-test="status"
                    className="px-2 py-6 text-left md:pr-4 "
                  >
                    {pledge.status === PropertyPledgeStatus.Confirmed
                      ? "Pledged"
                      : "Pending"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <h2 className="mt-8 mb-4 font-sans text-2xl font-bold leading-normal tracking-normal text-deep-blue">
          Upcoming Rents
        </h2>
        <table className="w-full card investments">
          <thead>
            <tr>
              <th className="font-semibold text-left border-b ">Property</th>
              <th className="font-semibold text-left border-b ">Due date</th>
              <th className="font-semibold text-center border-b m-hidden">
                Total rent
              </th>
              <th className="font-semibold text-center border-b m-hidden">
                Share percentage
              </th>
              <th className="font-semibold text-center border-b ">
                Your share
              </th>
            </tr>
          </thead>
          <tbody>
            {upcomingRentsLoading && (
              <TableSkeleton width={responsiveSize.width} height={100} />
            )}
            {!upcomingRentsLoading && !upcomingRentsData && (
              <tr>
                <td className="text-center empty" colSpan={5}>
                  No upcoming rents
                </td>
              </tr>
            )}
            {upcomingRentsData &&
              upcomingRentsData.getUpcomingRents.map((rent) => (
                <tr data-test="upcoming-property-id" key={rent.property.id}>
                  <td
                    data-test="upcoming-property-title"
                    className="px-4 py-6 text-left "
                  >
                    <Link
                      target="_blank"
                      to={`${AppRoute.PropertyDetails}?id=${rent.property.id}`}
                    >
                      {rent.property.title}
                    </Link>
                  </td>
                  <td data-test="due-date" className="px-4 py-6 text-left ">
                    {rent.dueDate === null
                      ? "Post-completion"
                      : format(new Date(rent.dueDate), "PPP")}
                  </td>
                  <td
                    data-test="total-rent"
                    className="py-6 pr-4 text-center m-hidden"
                  >
                    {toCurrencyDisplay(rent.totalRent)}
                  </td>
                  <td
                    data-test="share-percentage"
                    className="py-6 pr-4 text-center m-hidden"
                  >
                    {toPercentageDisplay(rent.sharePercentage)}
                  </td>
                  <td data-test="your-share" className="py-6 pr-4 text-center ">
                    {toHumanReadableCurrency(
                      (rent.totalRent * rent.sharePercentage) / 100 / 100,
                      2
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </section>
    </>
  );
}
