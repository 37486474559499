import React from "react";
import CallIcon from "../../../../assets/img/common/logo.svg";

const LoadingSite: React.FC = () => {
  return (
    <div className="fixed inset-0 z-[999999] overflow-auto flex items-center justify-center w-screen h-screen bg-gray-100">
      <div className="flex flex-col items-center space-y-4">
        {/* Animated Logo */}
        <div className="animate-bounce">
          <img src={CallIcon} alt="Site Logo" className="w-50 h-24" />
        </div>
        {/* Loading Text */}
        <p className="text-lg font-medium text-gray-700 animate-pulse">
          Loading, please wait...
        </p>
      </div>
    </div>
  );
};

export default LoadingSite;
