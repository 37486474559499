import { useLazyQuery, useQuery } from "@apollo/client";
import { FormEvent, useState } from "react";
import { Button, Dialog, DialogContent } from "@mui/material";
import { ReactComponent as SvgLogo } from "../../assets/img/common/logo.svg";
import { GlobalErrorHandler } from "../error/global_error.component";
import { VERIFY_PROMO_CODE } from "../../graphql/queries/coupon";
import CongratulationsPopup from "./congratulations-popup";
import { Modal } from "./modal";
import ConfettiCannon from "../../assets/img/congrats.png"; // Ensure this path is correct
import Confetti from "confetti-react";
import UnderMaintenance from "./under-maintenance";
import { User } from "../../__generated__/graphql";
import { QUERY_GET_ME } from "../../graphql/queries/get-me";

interface PromoModalProps {
  open: boolean;
  onClose: () => void;
}

export function PromoModal({ open, onClose }: PromoModalProps) {
  const [promoCode, setPromoCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [infoMessage, setInfoMessage] = useState<string>("");

  const {
    refetch,
    data: user,
    loading,
    error: userError,
  } = useQuery<{ me: User }>(QUERY_GET_ME, { fetchPolicy: "network-only" });
  const [verifyPromoCode, { data, error }] = useLazyQuery(VERIFY_PROMO_CODE, {
    fetchPolicy: "network-only",
  });

  const handlePromoCodeVerification = (e: FormEvent) => {
    e.preventDefault();

    const userId = user?.me.investorProfile?.id;
    if (userId) {
      const normalizedPromoCode = promoCode.replace(/[\s-_]/g, "");
      console.log("normalizedPromoCode", normalizedPromoCode);
      verifyPromoCode({
        variables: { code: normalizedPromoCode, email: user?.me.email },
      })
        .then((response) => {
          if (response.data?.verifyPromoCode) {
            setInfoMessage(response.data.verifyPromoCode.message);
            onClose();
            setPromoCode("");
          } else {
            setErrorMessage("Invalid promo code");
          }
        })
        .catch((err) => {
          setErrorMessage(err.message);
          console.error(err);
        });
    } else {
      setErrorMessage("Please sign in to use promo codes");
    }
  };

  const handleContinue = () => {
    onClose();
  };

  const handleCloseUnderMaintenance = () => {
    setErrorMessage("");
  };

  return (
    <>
      <GlobalErrorHandler />
      <UnderMaintenance
        errorType={errorMessage}
        onClose={handleCloseUnderMaintenance}
      />
      {/* Promo Code Modal wrapped in Modal */}
      {open && (
        <Modal title="" onClose={onClose}>
          <>
            <SvgLogo className="mx-auto text-center max-w-52" />
            <div className="mt-8 mb-10 max-w-[40rem]">
              <h3 className="font-sans font-bold leading-normal tracking-wide text-center text-md md:text-xl text-primary">
                Unlock Your Exclusive Offer
              </h3>
              <h4 className="font-sans text-center text-md md:text-xl font-bold leading-normal tracking-wide text-[#6E7B94] md:px-20">
                Apply your promo code below and unlock exclusive benefits!
              </h4>
            </div>

            <form
              onSubmit={handlePromoCodeVerification}
              className="mt-4 promo-form"
            >
              <label htmlFor="promoCode" className="block text-lg text-bold">
                Promo Code
              </label>
              <input
                type="text"
                id="promoCode"
                value={promoCode}
                onChange={(e) => {
                  setPromoCode(e.target.value);
                  setErrorMessage("");
                }}
                placeholder="Enter Promo Code"
                className="mt-2 bg-white text-[#14223d] font-sans tracking-normal leading-normal text-sm md:text-lg px-2 rounded-lg border-[1px] border-solid py-3 w-[100%]"
              />
              {errorMessage && errorMessage !== "UNDER_MAINTENANCE" && (
                <p className="text-red-500">{errorMessage}</p>
              )}

              <button
                type="submit"
                color="primary"
                className="mt-10 bg-[#14223d] hover:bg-[#0f1c33] text-white text-bold font-sans tracking-normal leading-normal text-sm md:text-lg px-12 rounded-lg py-3 w-[100%]"
              >
                Apply
              </button>
            </form>

            <button
              onClick={onClose}
              className="mt-2 bg-white hover:bg-[#E5E7EB] text-[#14223d] font-sans text-bold tracking-normal leading-normal text-sm md:text-lg px-12 rounded-lg border-[1px] border-solid py-3 w-[100%]"
            >
              Skip
            </button>
          </>
        </Modal>
      )}
    </>
  );
}

export default PromoModal;
