import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as SvgLogo } from "../../assets/img/common/logo.svg";
import { AppRoute } from "../../routes";
import { AuthService } from "../../services/auth.service";
import { useEffect, useState } from "react";
import Intercom from "@intercom/messenger-js-sdk";

export function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const cartCount = localStorage.getItem("cartCount")
    ? parseInt(localStorage.getItem("cartCount")!)
    : 0;

  function getClassNameForSidebarContainer() {
    let className = "sidebar-container";
    if (showMobileMenu) {
      className += " show-mobile";
    }
    return className;
  }

  function getClassNameForRoute(route: AppRoute) {
    let className =
      "flex align-middle items-center text-#686e7c text-base  border-radius";
    return location.pathname === route ? className + " selected" : className;
  }

  function logout() {
    AuthService.logout(navigate);
  }

  return (
    <div className={getClassNameForSidebarContainer()}>
      <a
        href="#mobile-menu"
        className="mobile-menu"
        onClick={() => setShowMobileMenu(!showMobileMenu)}
      >
        <i className="lni lni-menu " />
      </a>
      <nav
        className="bg-white sidebar box-shadow border-right"
        style={{ height: "100vh" }}
      >
        <div className=" mb-2.5 text-center flex items-center flex-col">
          <SvgLogo className=" max-w-36 mt-4 mb-2.5  " />
        </div>
        <div className="top-menu text-#686e7c border-y">
          <Link
            className={getClassNameForRoute(AppRoute.Properties)}
            to={AppRoute.Properties}
          >
            <i className="pl-2 lni lni-home" /> Marketplace
          </Link>
          <Link
            className={getClassNameForRoute(AppRoute.Portfolio)}
            to={AppRoute.Portfolio}
          >
            <i className="pl-2 lni lni-stats-up" />
            Portfolio
          </Link>
          <Link
            className={getClassNameForRoute(AppRoute.Vault)}
            to={AppRoute.Vault}
          >
            <i className="pl-2 lni lni-shield" /> Vault
          </Link>
          {/* <Link className={getClassNameForRoute(AppRoute.Rewards)} to={AppRoute.Rewards}><i className="pl-2 lni lni-star-empty" />Rewards</Link> */}
          <Link
            className={getClassNameForRoute(AppRoute.Cart)}
            to={AppRoute.Cart}
          >
            <div className="relative flex items-center">
              <i className="pl-2 lni lni-cart" /> Cart
              {cartCount > 0 && (
                <span className="absolute -top-2 left-full ml-1 flex items-center justify-center min-w-[20px] min-h-[20px] px-1.5 text-xs font-bold text-white bg-red-500 rounded-full">
                  {cartCount}
                </span>
              )}
            </div>
          </Link>
          <Link
            className={getClassNameForRoute(AppRoute.Analytics)}
            to={AppRoute.Analytics}
          >
            <i className="pl-2 lni lni-bar-chart" />
            Analytics
          </Link>
        </div>
        <div className="bottom-menu">
          {/* <Link className={getClassNameForRoute(AppRoute.Profile)} to={AppRoute.Profile}><i className="lni lni-user" />Profile</Link> */}
          <Link
            className={getClassNameForRoute(AppRoute.Profile)}
            to={AppRoute.Profile}
          >
            <i className="pl-2 lni lni-user" />
            Profile
          </Link>
          <a
            href="#logout"
            className={getClassNameForRoute(AppRoute.AccountLogin)}
            onClick={logout}
          >
            <i className="pl-2 lni lni-exit " />
            Logout
          </a>
          <Link
            className={getClassNameForRoute(AppRoute.Help)}
            to={AppRoute.Help}
          >
            <i className="pl-2 lni lni-envelope" />
            Help &amp; Support
          </Link>
        </div>
      </nav>
    </div>
  );
}
