// RegisterWebAuthn.tsx
import React from "react";
import { Button, Dialog, DialogContent } from "@mui/material";
import { ReactComponent as SvgLogo } from "../../assets/img/common/logo.svg"; // Ensure this path is correct
import {
  COMPLETE_WEBAUTHN_REGISTRATION,
  INITIATE_WEBAUTHN_REGISTRATION,
} from "../../graphql/mutations/twofa_methods";
import { useMutation } from "@apollo/client";

interface RegisterWebAuthnProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const RegisterWebAuthn: React.FC<RegisterWebAuthnProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const [initiateWebAuthnRegistration] = useMutation(
    INITIATE_WEBAUTHN_REGISTRATION
  );
  const [completeWebAuthnRegistration] = useMutation(
    COMPLETE_WEBAUTHN_REGISTRATION
  );

  const registerWebAuthn = async () => {
    try {
      console.log("Starting WebAuthn registration...");

      // Initiate WebAuthn registration
      const { data } = await initiateWebAuthnRegistration();
      console.log("Received data from initiateWebAuthnRegistration:", data);

      const { challenge, rp, user, pubKeyCredParams, timeout, attestation } =
        data.initiateWebAuthnRegistration;

      // Convert user ID from base64url to Uint8Array
      const userId = Uint8Array.from(atob(user.id), (c) => c.charCodeAt(0));
      // Using WebAuthn API to create a new credential
      console.log("Calling navigator.credentials.create...");
      const credential = await navigator.credentials.create({
        publicKey: {
          challenge: Uint8Array.from(challenge as string, (c: string) =>
            c.charCodeAt(0)
          ),
          rp,
          user: {
            ...user,
            id: userId,
          },
          pubKeyCredParams,
          timeout,
          attestation,
        },
      });

      if (!(credential instanceof PublicKeyCredential)) {
        throw new Error("Unexpected credential type.");
      }
      console.log("Credential response:", credential.response);
      const attestationResponse =
        credential.response as AuthenticatorAttestationResponse;

      console.log("Attestation Response:", attestationResponse);
      console.log(
        "Attestation Object (raw):",
        attestationResponse.attestationObject
      );
      console.log(
        "Client Data JSON (raw):",
        attestationResponse.clientDataJSON
      );

      // Prepare client data to send to the backend
      const clientData = JSON.stringify({
        id: credential.id,
        rawId: Array.from(new Uint8Array(credential.rawId)),
        challenge: Uint8Array.from(challenge, (c: string) => c.charCodeAt(0)),
        response: {
          attestationObject: Array.from(
            new Uint8Array(attestationResponse.attestationObject)
          ),
          clientDataJSON: Array.from(
            new Uint8Array(attestationResponse.clientDataJSON)
          ),
        },
        type: credential.type,
      });

      console.log("Client Data to send to backend:", clientData);

      // Send the WebAuthn response to the backend
      await completeWebAuthnRegistration({
        variables: { clientData: btoa(clientData) },
      });
      console.log("WebAuthn Registration Completed Successfully");

      // Call success callback if provided
      onSuccess && onSuccess();

      // Close the registration dialog
      onClose();
    } catch (error) {
      console.error("WebAuthn registration failed:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent className="bg-gray-100 mx-6 rounded-2xl text-center my-8">
        <SvgLogo className="max-w-52 mx-auto mb-4 mt-2" />
        <h1 className="font-sans text-deep-blue font-bold leading-normal text-4xl tracking-wide my-4">
          Secure & Seamless Login with Passkeys
        </h1>
        <p className="italic mb-2">
          Enable your device for quick and secure access.
        </p>
        <p className="text-lg mb-6 text-center px-10">
          Ready to set up your authentication?
        </p>
        <div className="flex flex-col items-center w-full max-w-[300px] mx-auto">
          <button
            className="mt-10 bg-[#14223d] hover:bg-[#0f1c33] text-white font-sans tracking-normal leading-normal text-lg px-12 rounded-md mb-4 py-4 w-full"
            onClick={registerWebAuthn}
          >
            Register Now!
          </button>

          <button
            onClick={onClose}
            className="mt-2 bg-white hover:bg-[#E5E7EB] text-[#14223d] font-sans font-bold tracking-normal leading-normal text-sm md:text-lg px-12 rounded-lg border-[1px] border-solid py-3 w-full"
          >
            Skip for Later
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RegisterWebAuthn;
