import { gql } from "@apollo/client";

export const VERIFY_PROMO_CODE = gql`
  query VerifyPromoCode($code: String!, $email: String!) {
    verifyPromoCode(code: $code, email: $email) {
      code
      message
      incentive
    }
  }
`;

export const GET_REFERRALS_BY_REFERRER = gql`
  query GetReferralsByReferrer {
    getReferralsByReferrer {
      referredInvestor {
        user {
          email
        }
      }
    }
  }
`;
