import { useMutation } from "@apollo/client";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { FormEvent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as SvgLogo } from "../../../assets/img/common/logo.svg";
import { GlobalErrorHandler } from "../../../components/error/global_error.component";
import LoginWebAuthn from "../../../components/login/login-webAuthn";
import UnderMaintenance from "../../../components/modal/under-maintenance";
import PasswordField from "../../../components/password-field/password-field";
import { MUTATION_LOGIN } from "../../../graphql/mutations/login";
import { AppRoute } from "../../../routes";
import { AuthService } from "../../../services/auth.service";

export function AccountLoginPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [infoMessage, setInfoMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [deviceId, setDeviceId] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loginWithPasskey, setLoginWithPasskey] = useState<boolean>(false);

  const [loginUser, { loading, error }] = useMutation(MUTATION_LOGIN, {
    errorPolicy: "all",
  });

  useEffect(() => {
    FingerprintJS.load().then((fp) => {
      fp.get().then((result) => {
        setDeviceId(result.visitorId);
      });
    });
  }, []);

  function getClassNameForRoute(route: AppRoute) {
    let className =
      "flex align-middle items-center text-#686e7c text-base  border-radius";
    return location.pathname === route ? className + " selected" : className;
  }

  const userAgent = navigator.userAgent;
  const osVersion = getOSVersion(userAgent);
  const deviceType = getDeviceType(userAgent);
  const model = getModel(userAgent);

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage(undefined);
    }
  }, [error]);

  useEffect(() => {
    if (AuthService.authenticate(navigate)) {
      navigate(AppRoute.Home);
    }
    localStorage.setItem("kycOnRegister", "true"); //initializing to show loading screen at first time
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get("email");
    if (emailParam) {
      setEmail(emailParam);
    }
  }, [location]);

  async function onSubmit(e?: FormEvent) {
    e?.preventDefault();
    setInfoMessage("Logging in. Please wait...");
    try {
      const { data } = await loginUser({
        variables: {
          email,
          password,
          isPasskey: loginWithPasskey,
          deviceId,
          osVersion,
          deviceType,
          model,
        },
      });
      localStorage.setItem("userId", data.login.userId);
      if (data.login.deviceStatus === "active") {
        AuthService.login(
          email,
          data.login.accessToken,
          deviceId,
          data.login.lastLogin
        );
        navigate(AppRoute.Home);
      } else {
        localStorage.setItem("deviceId", deviceId);
        navigate(`${AppRoute.LoginVefitication}?id=${deviceId}`);
      }
    } catch (e: any) {
      setErrorMessage(e.message);
      setInfoMessage("");
    }
  }

  function getOSVersion(userAgent: string) {
    let osVersion = "Unknown OS Version";

    if (/Windows NT 10.0/.test(userAgent)) osVersion = "Windows 10";
    else if (/Windows NT 6.3/.test(userAgent)) osVersion = "Windows 8.1";
    else if (/Windows NT 6.2/.test(userAgent)) osVersion = "Windows 8";
    else if (/Windows NT 6.1/.test(userAgent)) osVersion = "Windows 7";
    else if (/Mac OS X/.test(userAgent)) {
      const match = userAgent.match(/Mac OS X (\d+[\._]\d+[\._]?\d*)/);
      if (match) osVersion = `macOS ${match[1].replace(/_/g, ".")}`;
    } else if (/Android/.test(userAgent)) {
      const match = userAgent.match(/Android\s([0-9\.]*)/);
      if (match) osVersion = `Android ${match[1]}`;
    } else if (/iPhone OS/.test(userAgent)) {
      const match = userAgent.match(/iPhone OS (\d+[\._]\d+)/);
      if (match) osVersion = `iOS ${match[1].replace(/_/g, ".")}`;
    }

    return osVersion;
  }

  function getDeviceType(userAgent: string) {
    if (/android/i.test(userAgent)) return "android";
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream)
      return "ios";
    return "browser";
  }

  function getModel(userAgent: string) {
    let model = "Unknown Model";

    if (/iPhone/.test(userAgent)) model = "iPhone";
    else if (/iPad/.test(userAgent)) model = "iPad";
    else if (/Macintosh/.test(userAgent)) model = "Mac";
    else if (/Windows NT/.test(userAgent)) model = "Windows PC";
    else if (/Android/.test(userAgent)) {
      const match = userAgent.match(/Android.*;\s([a-zA-Z0-9\s]+)\sBuild/);
      if (match) model = match[1];
    }

    return model;
  }

  const handleCloseUnderMaintenance = () => {
    setErrorMessage("");
  };

  return (
    <section className="flex flex-col md:flex-row content account ">
      <div>
        <GlobalErrorHandler />
        <UnderMaintenance
          errorType={errorMessage}
          onClose={handleCloseUnderMaintenance}
        />
        <SvgLogo className="mx-auto mt-0 text-center max-w-52 lg:mt-16" />
        <h1
          data-test="heading"
          className="mt-10 mb-4 font-sans text-4xl font-bold leading-normal tracking-wide text-center text-deep-blue lg:text-5xl"
        >
          Investor Login
        </h1>
        <p
          data-test="welcome"
          className="max-w-screen-md mx-auto mb-10 -mt-2 font-sans text-lg font-normal leading-relaxed tracking-tight text-center text-deep-blue"
        >
          Welcome back to PropNerd! Your gateway to innovative real estate
          investment opportunities awaits. Sign in to access your personalized
          dashboard, manage your investments, and explore new properties ready
          for tokenization.
        </p>
      </div>
      {!loginWithPasskey && (
        <>
          <form
            className="w-full px-4 py-8 m-0 bg-white rounded-lg shadow-md card md:w-1/2 md:max-w-md md:p-8 "
            onSubmit={onSubmit}
          >
            <div className="flex flex-col gap-2 p-4">
              <label className="text-base font-bold tracking-normal">
                Email
              </label>
              <input
                data-test="email"
                className="box-border w-full p-4 text-base border border-gray-300 rounded"
                autoFocus
                type="email"
                value={email}
                placeholder="Enter your email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-2 px-4">
              <label className="text-base font-bold tracking-normal">
                Password
              </label>
              <PasswordField
                value={password}
                data-test="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
              />
            </div>
            {infoMessage && (
              <div
                data-test="info-message"
                className="px-12 py-6 mb-12 border rounded-lg bg-light-grey"
              >
                {infoMessage}
              </div>
            )}
            {errorMessage && errorMessage !== "UNDER_MAINTENANCE" && (
              <div
                data-test="error-message"
                className="px-12 py-6 mb-12 text-red-500 border rounded-lg bg-light-grey -600"
              >
                {errorMessage}
              </div>
            )}
            <div className="flex flex-col gap-2 p-4">
              <button
                data-test="login-btn"
                type="submit"
                className="btn btn-primary !w-full"
                disabled={loading}
                style={{ height: "3rem" }}
              >
                Login
              </button>
            </div>
            <div className="flex justify-center text-center">
              <Link
                data-test="forgot-link"
                className={getClassNameForRoute(AppRoute.AccountForget)}
                to={AppRoute.AccountForget}
              >
                Forgot password?
              </Link>
            </div>
            <div className="flex justify-center text-center mt-2">
              <Link
                to={AppRoute.AccountRegister}
                className="text-primary text-lg hover:text-deep-blue"
              >
                <span className="text-gray-500">Don't have an account? </span>
                <span className="text-primary font-semibold transform hover:underline">
                  Sign Up
                </span>
              </Link>
            </div>
            {/* <div className="flex items-center my-4 mx-6">
              <div className="flex-grow h-px bg-gray-300"></div>
              <span className="px-4 text-sm font-medium text-gray-500">OR</span>
              <div className="flex-grow h-px bg-gray-300"></div>
            </div>
            <div className="flex flex-col gap-2 px-4 mb-4">
              <button
                className="mt-2 bg-white hover:bg-[#E5E7EB] text-[#14223d] font-sans text-bold tracking-normal leading-normal text-sm md:text-lg px-12 rounded-lg border-[1px] border-solid py-3 !w-full"
                onClick={() => setLoginWithPasskey(true)}
              >
                Login with Passkey
              </button>
            </div> */}
          </form>
        </>
      )}
      {/* {loginWithPasskey && (
        <LoginWebAuthn
          show={loginWithPasskey}
          onClose={() => setLoginWithPasskey(false)}
          email={email}
          setEmail={setEmail}
          onLogin={onSubmit}
        />
      )} */}
    </section>
  );
}
