import { gql } from "@apollo/client";

export const QUERY_GET_TWOFA_METHODS = gql`
  query getTwofaMethods {
    getTwofaMethods {
      methodId
      methodType
      isEnabled
      isPrimary
      addedAt
    }
  }
`;
