import * as React from "react";
import { Sidebar } from "../../components/sidebar/sidebar";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GlobalErrorHandler } from "../../components/error/global_error.component";
import { QUERY_DASHBOARD_STATS } from "../../graphql/queries/get-analytics-stats";
import { GET_BALANCE } from "../../graphql/queries/get-balance";
import { QUERY_GET_PORTFOLIO_STATS } from "../../graphql/queries/get-portfolio-stats";
import { DashboardResponse, PortfolioStats } from "../../__generated__/graphql";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import { BarChart } from "@mui/x-charts/BarChart";
import Box from "@mui/material/Box";
import {
  toHumanReadableCurrencyDisplay,
  toCurrencyDisplay,
  toHumanReadableCurrency,
} from "../../utils/currency.util";
import { toPercentageDisplay } from "../../utils/string.util";
import { format } from "date-fns";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LineChart } from "@mui/x-charts/LineChart";
import * as d3 from "d3";
import SkeletonColor from "../../components/skeleton/skeleton";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";
import { error } from "console";
import { KycCheck } from "../account/kyc/kyc";

const useResponsiveSize = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:1100px)");

  return {
    width: isSmallScreen || isMediumScreen ? 300 : 600,
    height: isSmallScreen || isMediumScreen ? 275 : 550,
  };
};

const StyledText = styled("text")<{
  color?: string;
  fontSize?: number;
  fontWeight?: string;
}>(({ theme, color, fontSize, fontWeight }) => ({
  fill: color || theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: fontSize || 20,
  fontWeight: fontWeight || "bold",
  fontFamily: "Plus Jakarta Sans",
}));

interface PieCenterLabelProps {
  children: React.ReactNode;
  offsetY?: number;
  color?: string;
  fontSize?: number;
  fontWeight?: string;
}

type DataPoint = {
  label: number;
  value: number;
  color: string;
  property: string;
};

function PieCenterLabel({
  children,
  offsetY = 0,
  color,
  fontSize,
  fontWeight,
}: PieCenterLabelProps) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText
      x={left + width / 2}
      y={top + height / 2 + offsetY}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      {children}
    </StyledText>
  );
}

interface PieData {
  value: number;
  label: string;
  color: string;
}

interface Investment {
  property: {
    title: string;
  };
  percentage: number;
}

interface Pledge {
  amount: number;
  date: string;
}

interface Deposit {
  amount: number;
  date: string;
}

interface TransformedData {
  [key: string]: Array<{ label: number; value: number; color: string }>;
}

export function AnalyticsPage() {
  const responsiveSize = useResponsiveSize();
  const [data, setData] = useState<any>(null);
  const [hasData, setHasData] = useState<boolean>(true);

  const [dashboardData, setDashboardData] = useState<DashboardResponse | null>(
    null
  );
  const [pieData, setPieData] = useState<PieData[]>([]);
  const [totalDeposits, setTotalDeposits] = useState<number | null>(null);
  const [
    getDashboardStats,
    {
      data: dashboardResponse,
      error: dashboardError,
      loading: dashboardLoading,
    },
  ] = useLazyQuery(QUERY_DASHBOARD_STATS, { fetchPolicy: "network-only" });
  const [
    getStats,
    { data: statsResponse, error: statsError, loading: statsLoading },
  ] = useLazyQuery(QUERY_GET_PORTFOLIO_STATS, { fetchPolicy: "network-only" });
  const [
    getBalance,
    { data: balanceResponse, error: balanceError, loading: balanceLoading },
  ] = useLazyQuery(GET_BALANCE, { fetchPolicy: "network-only" });
  const [investingChartData, setInvestingChartData] = useState<
    { value: number; label: string; frontColor: string }[]
  >([]);
  const [depositChartData, setDepositChartData] = useState<
    { value: number; label: string; frontColor: string }[]
  >([]);
  const [barColors, setBarColors] = useState<{ [key: string]: string }>({});
  const [seriesNb, setSeriesNb] = React.useState(1);
  const [itemNb, setItemNb] = React.useState(7);
  const [skipAnimation, setSkipAnimation] = React.useState(false);
  const [seriesNb2, setSeriesNb2] = React.useState(1);
  const [itemNb2, setItemNb2] = React.useState(7);
  const [investingdates, setInvestingDates] = React.useState<string[]>([]);
  const [depositdates, setDepositDates] = React.useState<string[]>([]);
  const [formattedData, setFormattedData] = useState<any[]>([]);

  useAuthAndErrorHandling(dashboardError);
  useAuthAndErrorHandling(statsError);
  useAuthAndErrorHandling(balanceError);

  useEffect(() => {
    generateColors();
  }, []);

  useEffect(() => {
    const startDate = "2024-01-01";
    const endDate = "2024-12-31";

    getDashboardStats({ variables: { startDate, endDate } });
    getStats();
    getBalance();
  }, []);

  useEffect(() => {
    if (dashboardData) {
      const bColors = generateColors();
      const years = [2025, 2026, 2027, 2028, 2029];

      if (
        !dashboardResponse?.getDashboardStats.annualAppreciations ||
        dashboardResponse.getDashboardStats.annualAppreciations.length === 0
      ) {
        setHasData(false);
        return;
      }

      setHasData(true);

      const transformedData: TransformedData = years.reduce(
        (acc: TransformedData, year, index) => {
          acc[year] =
            dashboardResponse.getDashboardStats.annualAppreciations[index]?.map(
              (entry: any) => ({
                label: year,
                value: entry.annualAppreciation,
                color: bColors[entry.property.title],
                property: entry.property.title,
              })
            ) || [];
          return acc;
        },
        {}
      );

      // Ensure transformed data is de-duplicated by label and color
      for (const year in transformedData) {
        transformedData[year] = transformedData[year].reduce(
          (acc: any[], curr: any) => {
            const existing = acc.find(
              (i: any) => i.label === curr.label && i.color === curr.color
            );
            if (existing) {
              existing.value += curr.value;
            } else {
              acc.push(curr);
            }
            return acc;
          },
          []
        );
      }
      setData(transformedData);

      // Extract unique properties and their colors
      const propertiesMap: { [property: string]: string } = {};
      Object.values(transformedData).forEach((yearData) => {
        (yearData as DataPoint[]).forEach((propertyData) => {
          if (!propertiesMap[propertyData.property]) {
            propertiesMap[propertyData.property] = propertyData.color;
          }
        });
      });

      const properties = Object.keys(propertiesMap);

      // Format the data for the LineChart
      const formatted = properties.map((property) => ({
        label: property,
        data: Object.keys(transformedData).map((year) => {
          const yearData = (transformedData[year] as DataPoint[]).find(
            (d) => d.property === property
          );
          return yearData ? yearData.value / 10000 : 0;
        }),
        color: propertiesMap[property],
      }));

      setFormattedData(formatted);
    }
  }, [dashboardData, dashboardResponse]);

  const generateColors = (): { [key: string]: string } => {
    const _data = dashboardResponse?.getDashboardStats;
    if (
      !_data ||
      _data.annualAppreciations.length === 0 ||
      _data.annualAppreciations[0].length === 0
    ) {
      return {};
    }
    const keys = _data.annualAppreciations[0].map(
      (app: any) => app.property.title
    );

    const colorScale = d3
      .scaleOrdinal<string>()
      .domain(keys)
      .range(d3.schemeTableau10);

    const colorMap: { [key: string]: string } = {};
    keys.forEach((key: string) => {
      colorMap[key] = colorScale(key);
    });
    setBarColors(colorMap);
    return colorMap;
  };

  useEffect(() => {
    if (dashboardResponse) {
      setDashboardData(dashboardResponse.getDashboardStats);
      processDashboardData(dashboardResponse.getDashboardStats);
    }
  }, [dashboardResponse, dashboardData]);

  useEffect(() => {
    if (statsResponse && balanceResponse) {
      const totalDeposits = statsResponse.getPortfolioStats.totalDeposits;
      const totalInvestment = statsResponse.getPortfolioStats.totalInvestment;

      if (totalDeposits) {
        setTotalDeposits(totalDeposits);

        const investmentPercentage =
          totalDeposits === 0 ? 0 : (totalInvestment / totalDeposits) * 100;
        const balancePercentage =
          totalDeposits === 0
            ? 0
            : ((totalDeposits - totalInvestment) / totalDeposits) * 100;

        setPieData([
          {
            value: investmentPercentage,
            label: `${toPercentageDisplay(investmentPercentage * 100)} Invested`,
            color: "#14223d",
          },
          {
            value: balancePercentage,
            label: `${toPercentageDisplay(balancePercentage * 100)} Balance`,
            color: "#f5ca48",
          },
        ]);
      }
    }
  }, [statsResponse, balanceResponse]);

  function padDates(dates: string[]): string[] {
    const paddedDates = [...dates];
    while (paddedDates.length < 7) {
      paddedDates.push(`${paddedDates.length + 1}`);
    }
    return paddedDates;
  }

  const processDashboardData = (data: DashboardResponse) => {
    const colors = ["#f5ca48", "#d3d9e5", "#grey6", "#yellow", "#blue"];

    const investingData = data.pledges.map((pledge: Pledge, index: number) => ({
      value: pledge.amount,
      label: format(new Date(parseInt(pledge.date)), "M/d"),
      frontColor: colors[index % colors.length],
    }));

    const depositData = data.deposits.map(
      (deposit: Deposit, index: number) => ({
        value: deposit.amount,
        label: format(new Date(parseInt(deposit.date)), "M/d"),
        frontColor: colors[index % colors.length],
      })
    );

    const investingDatestemp = padDates(
      investingData.map((item) => item.label)
    );
    const depositDatestemp = padDates(depositData.map((item) => item.label));

    setInvestingDates(investingDatestemp);
    setDepositDates(depositDatestemp);

    setInvestingChartData(padDataToSevenDays(investingData));
    setDepositChartData(padDataToSevenDays(depositData));

    const colorMap: { [key: string]: string } = {};
    data.investments.forEach((investment: Investment, index: number) => {
      colorMap[investment.property.title] = colors[index % colors.length];
    });

    setBarColors(colorMap);
  };

  const padDataToSevenDays = (
    data: { value: number; label: string; frontColor: string }[]
  ) => {
    const paddedData = [...data];
    while (paddedData.length < 7) {
      paddedData.push({ value: 0, label: "", frontColor: "#cccccc" });
    }
    return paddedData;
  };

  const generateBarColors = (data: number[]) => {
    const maxValue = Math.max(...data);
    return data.map((value) => {
      if (value > 0.75 * maxValue) return "#14223d";
      if (value > 0.5 * maxValue) return "#f5ca48";
      return "#d3d9e5";
    });
  };

  const highlightScope2 = {
    highlighted: "series",
    faded: "global",
  } as const;
  const highlightScope = {
    highlighted: "series",
    faded: "global",
  } as const;

  const investingSeries = [
    {
      label: "Investing Activity",
      data: investingChartData.map((item) => item.value / 100),
      colors: generateBarColors(investingChartData.map((item) => item.value)),
      color: "#f5ca48",
    },
  ].map((s) => ({ ...s, highlightScope }));

  const depositSeries = [
    {
      label: "Deposit Activity",
      data: depositChartData.map((item) => item.value / 100),
      colors: generateBarColors(depositChartData.map((item) => item.value)),
      color: "#f5ca48",
    },
  ].map((s) => ({ ...s, highlightScope2 }));

  return (
    <>
      <KycCheck />
      <GlobalErrorHandler />
      <Sidebar />

      <section className="content cart">
        <h1 className="mb-4 font-sans text-deep-blue tracking-normal text-3xl font-bold leading-normal">
          Analytics
        </h1>
        <h2 className="font-sans text-deep-blue tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal">
          Overview
        </h2>
        <div className=" w-full md:w-fit border-solid border-lighter-grey bg-white border-1.5 py-8 px-8 rounded-2xl">
          {dashboardLoading || statsLoading || balanceLoading ? (
            <SkeletonColor
              width={responsiveSize.width}
              height={responsiveSize.height}
            />
          ) : pieData.length === 0 ? (
            <p>No data to display</p>
          ) : (
            <PieChart
              series={[
                { data: pieData, innerRadius: responsiveSize.width / 3.5 },
              ]}
              margin={{
                top: 0,
                bottom: 80,
                left: responsiveSize.width === 600 ? 100 : 20,
                right: 100,
              }}
              slotProps={{
                legend: {
                  direction: "column",
                  position: { vertical: "bottom", horizontal: "middle" },
                  padding: responsiveSize.width === 600 ? 10 : 2,
                  markGap: responsiveSize.width === 600 ? 300 : 50,
                  itemGap: 20,
                  labelStyle: {
                    fontSize: responsiveSize.width === 600 ? 16 : 12,
                    fontFamily: "Plus Jakarta Sans",
                    fill: "gray",
                  },
                },
              }}
              {...responsiveSize}
            >
              <PieCenterLabel
                offsetY={-10}
                color="#14223d"
                fontSize={responsiveSize.width / 15}
                fontWeight="900"
              >
                {totalDeposits !== null
                  ? toHumanReadableCurrencyDisplay(totalDeposits)
                  : "Loading..."}
              </PieCenterLabel>
              <PieCenterLabel
                offsetY={20}
                color="gray"
                fontSize={responsiveSize.width / 30}
                fontWeight="300"
              >
                Total deposits
              </PieCenterLabel>
            </PieChart>
          )}
        </div>

        <h2 className="font-sans text-deep-blue tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal">
          Activity
        </h2>
        <div className="flex flex-col md:flex-row gap-4">
          <div className="w-full md:w-1/2 border-solid border-lighter-grey bg-white border-1.5 py-8 px-8 rounded-2xl mr-3 flex flex-col items-center justify-center">
            <h3 className="font-sans text-deep-blue tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal">
              Daily investing activity
            </h3>
            <Box sx={{ width: "100%" }}>
              {dashboardLoading || statsLoading || balanceLoading ? (
                <SkeletonColor width={responsiveSize.width} height={300} />
              ) : (
                <BarChart
                  height={300}
                  margin={{ top: 50, bottom: 50, left: 65, right: 25 }}
                  series={investingSeries.slice(0, seriesNb).map((s) => ({
                    ...s,
                    data: s.data.slice(0, itemNb),
                  }))}
                  xAxis={[
                    {
                      scaleType: "band",
                      data: investingdates,
                      colorMap: {
                        type: "ordinal",
                        colors: investingSeries[0].colors,
                      },
                    },
                  ]}
                  skipAnimation={skipAnimation}
                  sx={{
                    "& .MuiChartsLegend-series text": {
                      fontFamily: "Plus Jakarta Sans !important",
                      fill: "gray !important",
                    },
                  }}
                  slotProps={{
                    bar: {
                      rx: 10,
                    },
                  }}
                />
              )}
            </Box>
          </div>
          <div className="w-full md:w-1/2 border-solid border-lighter-grey bg-white border-1.5 py-8 px-8 rounded-2xl flex flex-col items-center justify-center">
            <h3 className="font-sans text-deep-blue tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal">
              Daily deposit activity
            </h3>
            <Box sx={{ width: "100%" }}>
              {dashboardLoading || statsLoading || balanceLoading ? (
                <SkeletonColor width={responsiveSize.width} height={300} />
              ) : (
                <BarChart
                  height={300}
                  margin={{ top: 50, bottom: 50, left: 65, right: 25 }}
                  series={depositSeries.slice(0, seriesNb2).map((s) => ({
                    ...s,
                    data: s.data.slice(0, itemNb2),
                    colors: depositSeries[0].colors,
                  }))}
                  xAxis={[
                    {
                      scaleType: "band",
                      data: depositdates,
                      colorMap: {
                        type: "ordinal",
                        colors: depositSeries[0].colors,
                      },
                    },
                  ]}
                  skipAnimation={skipAnimation}
                  sx={{
                    "& .MuiChartsLegend-series text": {
                      fontFamily: "Plus Jakarta Sans !important",
                      fill: "gray !important",
                    },
                  }}
                  slotProps={{
                    bar: {
                      rx: 10,
                    },
                  }}
                />
              )}
            </Box>
          </div>
        </div>
        <h2 className="font-sans text-deep-blue tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal">
          Projected
        </h2>
        <div className=" w-full md:w-fit flex flex-col items-center border-solid border-lighter-grey bg-white border-1.5 py-8 px-8 rounded-2xl">
          {dashboardLoading || statsLoading || balanceLoading ? (
            <SkeletonColor
              width={responsiveSize.width}
              height={responsiveSize.height}
            />
          ) : (
            <>
              <h3 className="font-sans  text-deep-blue tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal">
                Projected values for invested properties
              </h3>
              <LineChart
                width={responsiveSize.width}
                height={responsiveSize.height}
                series={formattedData}
                xAxis={[
                  { scaleType: "point", data: data ? Object.keys(data) : [] },
                ]}
                yAxis={[
                  {
                    valueFormatter: (value: any) =>
                      toHumanReadableCurrency(parseInt(value) * 100, 1),
                  },
                ]}
                margin={{ top: 50, bottom: 100, left: 50, right: 25 }}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "bottom", horizontal: "middle" },
                    padding: 0,
                    itemMarkWidth: 20,
                    itemMarkHeight: 2,
                    markGap: 5,
                    itemGap: 10,
                  },
                }}
              />
            </>
          )}
          {!hasData &&
            !dashboardLoading &&
            !statsLoading &&
            !balanceLoading && (
              <span>
                Not enough data. You will see your analytics once you start
                making deposits and investments.
              </span>
            )}
        </div>
      </section>
    </>
  );
}
