import { useMutation, useQuery } from "@apollo/client";
import { GlobalErrorHandler } from "../../components/error/global_error.component";
import { Sidebar } from "../../components/sidebar/sidebar";
import { QUERY_GET_ME } from "../../graphql/queries/get-me";
import { User } from "../../__generated__/graphql";
import { useEffect, useState } from "react";
import ProfileSkeleton from "../../components/skeleton/profileskeleton";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";
import {
  MUTATION_UPDATE_USER,
  MUTATION_CHANGE_PASSWORD,
  MUTATION_VERIFY_OTP_PHONE,
  MUTATION_RESEND_OTP_PHONE,
} from "../../graphql/mutations/user";
import "react-phone-number-input/style.css";
import PhoneInputWithCountrySelect, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import PasswordField from "../../components/password-field/password-field";
import UnderMaintenance from "../../components/modal/under-maintenance";
import { KycCheck } from "../account/kyc/kyc";
import RegisterWebAuthn from "../../components/modal/register-webAuthn";
import LoginWebAuthn from "../../components/login/login-webAuthn";
import { setEmitFlags } from "typescript";
import { Link } from "react-router-dom";
import { AppRoute } from "../../routes";

export function ProfilePage() {
  const { data, loading, error } = useQuery<{ me: User }>(QUERY_GET_ME, {
    fetchPolicy: "network-only",
  });

  const [updateUser, { loading: updateLoading }] = useMutation(
    MUTATION_UPDATE_USER,
    { fetchPolicy: "network-only" }
  );
  const [verifyOTPToPhone, { loading: verifyOtpPhoneLoading }] = useMutation(
    MUTATION_VERIFY_OTP_PHONE,
    { fetchPolicy: "network-only" }
  );
  const [resendOTPToPhone, { loading: resendOtpLoading }] = useMutation(
    MUTATION_RESEND_OTP_PHONE,
    { fetchPolicy: "network-only" }
  );
  const [changePassword, { loading: passwordLoading }] = useMutation(
    MUTATION_CHANGE_PASSWORD,
    { fetchPolicy: "network-only" }
  );

  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [phone, setPhone] = useState<string | undefined>(undefined);

  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [reEnterPassword, setReEnterPassword] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [passwordChangeError, setPasswordChangeError] = useState<string | null>(
    null
  );

  const [otp, setOtp] = useState<string>("");
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [phoneChanged, setPhoneChanged] = useState(false);
  const [otpInfo, setOtpInfo] = useState<string | null>(null);
  const [otpError, setOtpError] = useState<string | null>(null);
  const [countdown, setCountdown] = useState<number>(0);
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);
  const [isTFAEnabled, setIsTFAEnabled] = useState(false);

  const handleToggle = () => {
    const newPasskeyEnabled = !isTFAEnabled;

    console.log("Updating passkeyEnabled to:", newPasskeyEnabled); // Debugging

    updateUser({
      variables: {
        firstName,
        lastName,
        phone,
        passkeyEnabled: newPasskeyEnabled,
      },
      refetchQueries: [{ query: QUERY_GET_ME }],
    })
      .then((response) => {
        if (response.data.updateUser.success) {
          setIsTFAEnabled(newPasskeyEnabled);
        } else {
          setErrorMessage("Failed to update Passkey. Please try again");
        }
      })
      .catch((error) => {
        console.error("Error updating passkeyEnabled:", error);
        alert("Error: " + error.message);
      });
  };

  useAuthAndErrorHandling(error);

  useEffect(() => {
    if (data?.me) {
      setFirstName(data.me.firstName ?? undefined);
      setLastName(data.me.lastName ?? undefined);
      setEmail(data.me.email ?? undefined);
      setPhone(data.me.phone ?? undefined);
      setIsTFAEnabled(data.me.passkeyEnabled ?? false);
    }
  }, [data]);

  useEffect(() => {
    if (countdown === 0) {
      setIsResendDisabled(false);
      return;
    }
    const timer = setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer
  }, [countdown]);

  async function handleProfileSave(e: React.FormEvent) {
    e.preventDefault();
    setErrorMessage("");

    // Validate phone number
    if (!isValidPhoneNumber(phone || "")) {
      setErrorMessage("Please enter a valid phone number");
      return;
    }

    try {
      const response = await updateUser({
        variables: {
          firstName,
          lastName,
          phone: phone,
        },
      });

      if (response?.data?.updateUser?.message === "OTP sent") {
        setShowOtpModal(true);
        setOtpError(null);
        // Reset countdown and disable the resend button
        setCountdown(30);
        setIsResendDisabled(true);
      } else {
        setShowOtpModal(false);
        setOtpError(null);
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }

  function handleVerifyOtp(otp: string) {
    setOtpError(null);
    setOtpInfo(null);
    verifyOTPToPhone({ variables: { phone, otp } })
      .then(({ data }) => {
        if (data?.verifyOTPToPhone?.success) {
          setShowOtpModal(false);
          setOtpError(null);
          setPhoneChanged(false);
          window.location.reload();
        } else {
          setOtpError("Invalid OTP. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error);
        setOtpError(`An error occurred: ${error.message}`);
      });
  }

  function handleResendOtp() {
    setOtpError("");
    setOtpInfo("");
    resendOTPToPhone({ variables: { phone } })
      .then(({ data }) => {
        if (data?.resendOTPToPhone?.success) {
          setOtpInfo("OTP has been resent to your phone.");
          // Reset countdown and disable the resend button
          setCountdown(30);
          setIsResendDisabled(true);
        } else {
          setOtpError("Failed to resend OTP. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error resending OTP:", error);
        setOtpError(`An error occurred: ${error.message}`);
      });
  }

  function handleCloseModal() {
    setShowOtpModal(false);
    setPhone(phone);
    setPhoneChanged(false);
  }

  async function handleChangePassword(e: React.FormEvent) {
    e.preventDefault();
    setPasswordChangeError(null);

    if (newPassword && newPassword !== reEnterPassword) {
      setPasswordChangeError(
        "New password and re-enter password do not match."
      );
      return;
    }

    if (!oldPassword || !newPassword || !reEnterPassword) {
      setPasswordChangeError("All password fields must be filled.");
      return;
    }

    try {
      await changePassword({
        variables: {
          oldPassword,
          newPassword,
        },
      });

      window.location.reload();
    } catch (error: any) {
      if (error.message.includes("Incorrect old password")) {
        setPasswordChangeError("Old password is incorrect.");
      } else {
        setPasswordChangeError("An error occurred: " + error.message);
      }
    }
  }

  const handleCloseUnderMaintenance = () => {
    setErrorMessage("");
  };

  return (
    <>
      <KycCheck />
      <GlobalErrorHandler />
      <UnderMaintenance
        errorType={errorMessage}
        onClose={handleCloseUnderMaintenance}
      />
      <Sidebar />
      <section className="flex flex-col space-y-8 content portfolio">
        <h1 className="mb-4 font-sans text-3xl font-bold leading-normal tracking-normal text-deep-blue">
          Profile
        </h1>
        {loading ? (
          <div className="flex flex-row justify-between w-full gap-8">
            <div className="w-1/2 card">
              <ProfileSkeleton />
            </div>
            <div className="w-1/2 card">
              <ProfileSkeleton />
            </div>
          </div>
        ) : (
          <>
            {/* <div className="card flex justify-between items-center w-full">
              <Link to={AppRoute.TwoFA}>
                <button className="btn">Manage 2FA Methods</button>
              </Link>

              <div className="flex items-center gap-2">
                <h2 className="text-center text-xl font-bold leading-normal text-deep-blue">
                  Enable login via pass-key
                </h2>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={isTFAEnabled}
                    disabled={updateLoading}
                    onChange={handleToggle}
                  />
                  <div
                    className={`w-11 h-6 rounded-full transition-colors ${
                      updateLoading
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-deep-blue peer-checked:bg-[#FFD000]"
                    }`}
                  >
                    <span
                      className={`ml-2 text-sm font-bold ${
                        updateLoading ? "text-gray-400" : "text-gray-900"
                      }`}
                    >
                      {isTFAEnabled ? "ON" : "Off"}
                    </span>
                  </div>
                </label>
              </div>
            </div> */}
            {data &&
              data.me &&
              data.me.investorProfile?.solanaWalletAddress && (
                <div className="card">
                  <h2 className="mb-6 text-2xl font-bold leading-normal text-deep-blue">
                    Wallet Address
                  </h2>
                  <p className="w-auto px-4 py-2 bg-gray-100 rounded-lg">
                    {data?.me.investorProfile?.solanaWalletAddress}
                  </p>
                </div>
              )}
            {data && data.me && (
              <div className="flex flex-row justify-between w-full gap-8">
                {/* Profile Update Card */}
                <form className="w-1/2 card" onSubmit={handleProfileSave}>
                  <div className="form-group">
                    <label>First name</label>
                    <input
                      data-test="first-name"
                      autoFocus
                      value={firstName || ""}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="Enter your first name"
                    />
                  </div>
                  <div className="mt-6 form-group">
                    <label>Last name</label>
                    <input
                      data-test="last-name"
                      value={lastName || ""}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Enter your last name"
                    />
                  </div>
                  <div className="mt-6 form-group">
                    <label>Email address (cannot be changed)</label>
                    <input
                      data-test="email"
                      type="email"
                      value={email || ""}
                      disabled
                      placeholder="Enter your email address"
                    />
                  </div>
                  <div className="mt-6 form-group">
                    <label>Phone number</label>
                    <PhoneInputWithCountrySelect
                      data-test="phone-number"
                      defaultCountry="GB"
                      value={phone || ""}
                      onChange={setPhone}
                      placeholder="Enter your phone number"
                    />
                  </div>

                  {errorMessage && errorMessage !== "UNDER_MAINTENANCE" && (
                    <p
                      data-test="save-error-message"
                      className="my-4 text-red-500"
                    >
                      {errorMessage}
                    </p>
                  )}

                  <div className="mt-6 form-group">
                    <button
                      type="submit"
                      data-test="save-btn"
                      className="btn btn-lg"
                      style={{ height: 50, maxWidth: 200 }}
                      disabled={updateLoading}
                    >
                      Save Profile
                    </button>
                  </div>
                </form>

                {showOtpModal && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="relative w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
                      <button
                        onClick={handleCloseModal}
                        className="absolute text-gray-400 top-2 right-2 hover:text-gray-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>

                      <div className="flex flex-col items-center gap-4">
                        <h2
                          data-test="h2-heading"
                          className="mb-6 text-2xl font-bold leading-normal text-center text-deep-blue"
                        >
                          Verify Phone Number
                        </h2>

                        <p className="mb-8 text-center">
                          We've sent a One-Time Password (OTP) to{" "}
                          <span data-test="phone" className="font-semibold">
                            {phone}
                          </span>
                          . Please enter it below.
                        </p>

                        <div className="flex flex-col w-full gap-2 p-4">
                          <label className="mb-2 -mt-2 text-base font-bold tracking-normal text-left">
                            Verification code
                          </label>
                          <input
                            className="box-border w-full p-4 text-base border border-gray-300 rounded"
                            autoFocus
                            type="text"
                            data-test="otp"
                            value={otp}
                            placeholder="Enter your verification code"
                            onChange={(e) => setOtp(e.target.value)}
                          />
                        </div>
                        {countdown > 0 && (
                          <div className="flex justify-end px-4 w-[100%]">
                            <h5 className="px-2 text-bold">
                              Resend available in
                            </h5>
                            <h5 className="text-bold text-yellow">
                              00:{countdown} Sec
                            </h5>
                          </div>
                        )}

                        <div className="flex flex-row flex-wrap justify-center w-full gap-2 p-4">
                          <button
                            type="submit"
                            data-test="submit-btn"
                            className="w-[48%] btn btn-primary"
                            style={{ height: "3rem" }}
                            onClick={() => handleVerifyOtp(otp)}
                            disabled={verifyOtpPhoneLoading}
                          >
                            {verifyOtpPhoneLoading ? "Verifying..." : "Verify"}
                          </button>
                          <button
                            className="w-[48%] btn btn-secondary disabled:opacity-20"
                            data-test="resend-btn"
                            type="button"
                            style={{ height: "3rem" }}
                            onClick={handleResendOtp}
                            disabled={resendOtpLoading || isResendDisabled}
                          >
                            {resendOtpLoading ? "Resending..." : "Resend OTP"}
                          </button>
                        </div>
                      </div>

                      {otpInfo && (
                        <p
                          data-test="info-message"
                          className="text-center text-green-500"
                        >
                          {otpInfo}
                        </p>
                      )}
                      {otpError && (
                        <p
                          data-test="error-message"
                          className="text-center text-red-500"
                        >
                          {otpError}
                        </p>
                      )}
                    </div>
                  </div>
                )}

                {/* Password Change Card */}
                <form className="w-1/2 card" onSubmit={handleChangePassword}>
                  <h2 className="mb-6 text-2xl font-bold leading-normal text-deep-blue">
                    Change Password
                  </h2>
                  <div className="flex flex-col gap-2">
                    <label className="mb-2 -mt-2 font-semibold">
                      Old Password
                    </label>
                    <PasswordField
                      value={oldPassword}
                      data-test="new-password"
                      onChange={(e) => setOldPassword(e.target.value)}
                      placeholder="Enter your old password"
                      className="pr-16"
                    />
                  </div>

                  <div className="flex flex-col gap-2 mt-6">
                    <label className="mb-2 -mt-2 font-semibold">
                      New Password
                    </label>
                    <PasswordField
                      data-test="new-password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="Enter your new password"
                    />
                  </div>
                  <div className="flex flex-col gap-2 mt-6">
                    <label className="mb-2 -mt-2 font-semibold">
                      Re-enter Password
                    </label>
                    <PasswordField
                      data-test="re-enter-password"
                      value={reEnterPassword}
                      onChange={(e) => setReEnterPassword(e.target.value)}
                      placeholder="Re-enter your new password"
                    />
                  </div>

                  <div className="mt-12 form-group">
                    <button
                      type="submit"
                      data-test="update-btn"
                      className="btn btn-lg"
                      style={{ height: 50, maxWidth: 200 }}
                      disabled={
                        passwordLoading ||
                        !oldPassword ||
                        !newPassword ||
                        !reEnterPassword
                      }
                    >
                      Update
                    </button>
                  </div>

                  {passwordChangeError && (
                    <p
                      data-test="password-error-message"
                      className="mt-4 text-red-500"
                    >
                      {passwordChangeError}
                    </p>
                  )}
                </form>
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
}
