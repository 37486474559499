import { gql } from "@apollo/client";

export const QUERY_GET_PORTFOLIO_STATS = gql`
  query getPortfolioStats {
    getPortfolioStats {
      portfolioValue
      monthlyIncome
      totalRentalIncome
      totalAppreciation
      numProperties
      occupancyRate
      annualRentYield
      totalInvestment
      totalInvestmentLast12Months
      totalDeposits
    }
  }
`;
